  import React, { Component, useEffect, useState } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col, Table, Modal } from "react-bootstrap";
import { Card } from "../components/Card/Card.jsx";
import { StatsCard } from "../components/StatsCard/StatsCard.jsx";
import { Tasks } from "../components/Tasks/Tasks.jsx";
import jwt_decode from "jwt-decode";
import moment from "moment";
import _ from "lodash";
import isEmpty from "is-empty";
//import ReactPaginate from "react-paginate";
import { NotificationManager } from "react-notifications";
import {
  dataPie,
  legendPie,
  dataSales,
  optionsSales,
  responsiveSales,
  legendSales,
  dataBar,
  optionsBar,
  responsiveBar,
  legendBar,
} from "../variables/Variables.jsx";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  ChartData,
  ChartArea,
} from "chart.js";
import ReactPaginate from "react-paginate";
import { Bar, Pie, Bubble } from "react-chartjs-2";
import axios from "axios";
import SyncLoader from "react-spinners/SyncLoader";
const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 9999;
`;
const MerchantDashboard = () => {
  var date = new Date();
  const [revenue, setRevenue] = useState("");
  const [order, setOrder] = useState("");
  const [graphPlot, setGraphPlot] = useState({});
  const [topProducts, setTopProducts] = useState([]);
  const [fulfilOrder, setFulfilOrder] = useState();
  const [isLoaded, setLoader] = useState(true);
  const [Revenuedate, setRevenuedate] = useState([]);
  const [RevenueAmount, setRevenueAmount] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "years").format("Y-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("Y-MM-DD"));
  const token = localStorage.getItem("token");
  const decode = jwt_decode(token);
  const [supllierList, setsupllierList] = useState([]);
  const [searchSku, setsearchSku] = useState("");
const [searchName, setsearchName] = useState("");
const [currentPage, setcurrentPage] = useState(parseInt(localStorage.getItem("p.currentpagea")) || 1);
const [showPerPage, setshowPerPage] = useState(10000);
const [query, setquery] = useState({});
const [marketPlaceWiseData, setmarketPlaceWiseData] = useState([]);
const [BrandWiseData, setBrandWiseData] = useState([]);
const [pageCount, setPageCount] = useState(0);
const [pageData, setPageData] = useState([]);
const [CurrentPage, setCurrentPage] = useState(1);

  const [loadingp,setLoadingp]= useState(true);
  const [productData,setProductDatas]= useState([]);
  // const [currentPage,setCurrentPage]= useState(parseInt(localStorage.getItem("p.currpage"))||1)
  // const [showPerPage,setShowPerPage]=useState(5);
  // const [pageCount,setPageCount]= useState(0);
  const [productTotalCount,setProductTotalCount]= useState(0);
  
  const [productImageUrl,setProductImageUrl]= useState("");
  const [modalShow1,setModalShow1]= useState(false);
  const [productPriceInfo,setProductPriceInfo]= useState({});
  const [productSku,setProductSku]= useState("00000000");





  useEffect(() => {
    // getProductsDatas(currentPage,showPerPage,query);
    getSupplierList();
    getProductData();
    totalOrders();
    graphData();
    getSupplierList();
    getProductsData(
      currentPage,
      showPerPage,
      query
    );
    getBrandWiseData(  currentPage,
      showPerPage,
      query)
    getMarketPlaceWiseData(CurrentPage,showPerPage,query);
    top();
    if (!localStorage.getItem("refreshToken")) {
      refresh();
    }
  }, []);

  //Data For MarketPlace wise

  const getMarketPlaceWiseData=(page, perPage, query)=> {
    axios
      .get(
        `/po/getMarketPlaceWiseData?page=${page}&limit=${perPage}&query=${
          query ? query : "{}"
        }`
      )
      .then((result) => {
        console.log("87878787878787878787");
        setmarketPlaceWiseData(result.data)
        console.log('getMarketPlaceWiseData',result.data);
        setPageCount(Math.ceil(result.data.count / showPerPage));
        setPageData(result.data);
      
      })
      .catch((err) => {
        console.log(err)
        NotificationManager.error(
          "Something went wrong, while getting products data"
        );
      });
  }
  const handlePageClick = (e) => {
    // setLoading(true);
    setCurrentPage(e.selected + 1);
    // getOrders(selectStore, e.selected + 1, showPerPage, {});
  };

  //Data for Brand wise

  const getBrandWiseData=(page, perPage, query)=> {
    axios
      .get(
        `/po/getBrandeWiseData?page=${page}&limit=${perPage}&query=${
          query ? query : "{}"
        }`
      )
      .then((result) => {
        console.log("00000000000000");
        setBrandWiseData(result.data)
        console.log(result);
      
      })
      .catch((err) => {
        // console.log(err)
        NotificationManager.error(
          "Something went wrong, while getting products data"
        );
      });
  }


const getProductsData=(page, perPage, query)=> {
    axios
      .get(
        `/adminApi/getProducts?page=${page}&limit=${perPage}&query=${
          query ? query : "{}"
        }`
      )
      .then((result) => {
        // console.log("9999999999999999999999999999");
        // console.log(result);
        // this.setState({
        //   productsData: result.data.items,
        //   productImageUrl: result.data.url,
        //   pageCount: Math.ceil(result.data.totalCount / this.state.showPerPage),
        //   currentPage: page,
        //   productTotalCount: result.data.totalCount,
        //   loading: false,
        //   query,
        // });
        // localStorage.setItem("p.currentpagea", page);
        // if (_.isEmpty(query)) {
        //   document.getElementById("supplierList").selectedIndex = 0;
        //   document.getElementById("categoryList").selectedIndex = 0;
        //   this.setState({ searchName: "", searchSku: "" });
        // }
      })
      .catch((err) => {
        // console.log(err)
        NotificationManager.error(
          "Something went wrong, while getting products data"
        );
      });
  }


  const getSupplierList=()=> {
    axios.get("/shopperrds/supplierList").then((response) => {
      setsupllierList(response.data)
     // this.setState({ supllierList: response.data });
    });
  }

  const refresh = () => {
    localStorage.setItem("refreshToken", "active");
    window.location.reload();
  };

  const dataForRevenue = {
    labels: Revenuedate,
    datasets: [
      {
        label: "Revenue",
        data: RevenueAmount,
        backgroundColor: "#336699",
        barPercentage: 0.2,
      },
    ],
  };

   const optionsForRevenue = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
    },
  };
  const getProductData = () => {
    axios
      .get(
        "/shopperr/leftOrdermerchantShopify/" +
          decode.id +
          "/" +
          startDate +
          "/" +
          endDate
      )
      .then((res) => {
        setFulfilOrder(res.data);
      });
  };
  const totalOrders = () => {
    axios
      .get(
        "/merchant/merchantDashboardOrder/" +
          decode.id +
          "/" +
          startDate +
          "/" +
          endDate
      )
      .then((ord) => {
        console.log("orders are", ord.data);
        setOrder(ord.data);
      });
  };

  const graphData = () => {
    axios
      .get(
        "/shopperrds/merchantDasboardGraph/" +
          decode.id +
          "/" +
          startDate +
          "/" +
          endDate
      )
      .then((response) => {
        console.log("response is in MerchantDashbioard is ------");
        // console.log(response);
        setRevenuedate(response.data.date);
        setRevenueAmount(response.data.revenue);
        // if (response.data.date.length != 0) {
        //   let data = {
        //     labels: response.data.date,
        //     series: [response.data.revenue],
        //   };
        //   let income = response.data.revenue.reduce((a, b) => a + b, 0);
        //   setRevenue(income);
        //   setGraphPlot(data);
        // } else {
        //   let data = {
        //     labels: response.data.date,
        //     series: [response.data.revenue],
        //   };
        //   let income = response.data.revenue.reduce((a, b) => a + b, 0);
        //   setRevenue(income);
        //   setGraphPlot(data);
        //   NotificationManager.error("No Data Found");
        // }
        setLoader(false);
      });
  };

  const top = () => {
    axios.get("/shopperrds/merchantTopProducts/" + decode.id).then((response) => {
      setTopProducts(response.data);
    });
  };

  const sortByDate = async () => {
    setLoader(true);
    getProductData();
    totalOrders();
    top();
    graphData();
  };


  

  const showProductPrice=(item)=>{
    axios.get(`/adminApi/getProductPrice?id=${item._id}`).then((res)=>{
        setModalShow1(true);
        setProductPriceInfo(res.data);
        setProductSku(res.data.sku);
    })
  }

  return (
    <div>
      <SyncLoader
        css={override}
        size={15}
        margin={6}
        color={"#3E3E3E"}
        loading={isLoaded}
      />
      <br></br>
      <div className="content bdycontent merchantStore">
        <div>
          <Grid fluid>
            <Row>
              <Col md={8}>
                <div
                  style={{
                    textAlign: "right",
                    alignSelf: "right",
                    display: "flex",
                    justifyContent: "space-between",
                  
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      required
                      className=" border focus:outline-none text-sm  rounded-full w-full p-0 px-3 text-grey-darker"
                      id="date"
                      type="date"
                      placeholder="Start from"
                      autoComplete="bday-day"
                      max={new Date()}
                      min={new Date("20-02-2019")}
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      style={{ height: 45, 
                      width: "350px"}}
                    />

                    <input
                      required
                      placeholder="To date"
                      className=" border focus:outline-none text-sm  rounded-full w-full p-0 px-3 text-grey-darker"
                      id="date"
                      type="date"
                      autoComplete="bday-day"
                      max={new Date()}
                      min={new Date("20-02-2019")}
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      style={{
                        height: "45",
                        width: "350px",
                        marginLeft: "20px",
                      }}
                    />
                    <div
                      style={{
                        backgroundColor: "#ffa000",
                        width: "280px",
                        height: "45px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 4,
                        marginLeft: "20px",
                        cursor: "pointer",
                        color: "#fff",
                        fontSize: "15px",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                      onClick={() => sortByDate()}
                    >
                      Get
                    </div>
                  </div>
                </div>
                <br />
                <Card
                  id="chartHours"
                  title="Revenue Chart"
                  content={
                  
                       <Bar options={optionsForRevenue} data={dataForRevenue} />
                   
                  }
                />
              </Col>
              <br />
              <br />
              <br />
              <br />
              <Col lg={4} sm={6} >
                <StatsCard
                  style={{ border: "1px solid red" }}
                  bigIcon={<i className="fa fa-first-order text-info" />}
                  statsText="Total Orders"
                  statsValue={order}
                  statsIcon={<i className="fa fa-refresh" />}
                  statsIconText="Updated"
                  func={totalOrders}
                />
              </Col>
              <Col lg={4} sm={6}>
                <StatsCard
                  bigIcon={<i className="fa fa-money text-info" />}
                  statsText="Total Revenue"
                  statsValue={`₹ ${new Intl.NumberFormat("en-IN").format(
                    revenue
                  )}`}
                  statsIcon={<i className="fa fa-refresh" />}
                  statsIconText="Updated"
                  func={graphData}
                />
              </Col>
              <Col lg={4} sm={6}>
                <StatsCard
                  bigIcon={<i className="fa fa-ship text-info" />}
                  statsText="Orders Fulfill Left"
                  statsValue={fulfilOrder}
                  statsIcon={<i className="fa fa-refresh" />}
                  statsIconText="Updated"
                  func={getProductData}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Card
                  title="Top Selling Products"
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <Table striped hover size="sm">
                      <thead>
                        <th>Title</th>
                        <th>Sku</th>
                        <th>Price</th>
                      </thead>
                      <tbody>
                        {topProducts.length ?
                          topProducts.map((item, key) => {
                            return (
                              <tr key={key}>
                                <td>{item.name}</td>
                                <td>{item.sku}</td>
                                <td>
                                  {" "}
                                  ₹{" "}
                                  {new Intl.NumberFormat("en-IN").format(
                                    item.price
                                  )}{" "}
                                </td>
                              </tr>
                            );
                          }): null}
                      </tbody>
                    </Table>
                  }
                />
              </Col>
            </Row>     
          </Grid>
        </div>
      </div>
      {/* <Modal
            size="md"
            className="planModal"
            show={modalShow1}
            onHide={() => setModalShow1(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Price Detail #{productSku}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th className="text-center">Sea Price</th>
                    <th className="text-center">Air Price</th>
                    <th className="text-center">DropShipper</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Ex Factory Price</td>
                    <td className="text-right">
                      {productPriceInfo.seaPrice
                        ? productPriceInfo.seaPrice.factoryPrice
                        : "-"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.finalAirPrice
                        ? productPriceInfo.finalAirPrice.factoryPrice
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.dropshipperPrice
                        ? productPriceInfo.dropshipperPrice
                            .factoryPrice
                        : "Na"}
                    </td>
                  </tr>
                  <tr>
                    <td>SWC</td>
                    <td className="text-right">
                      {productPriceInfo.seaPrice
                        ? productPriceInfo.seaPrice.SWC
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.finalAirPrice
                        ? productPriceInfo.finalAirPrice.SWC
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.dropshipperPrice
                        ? productPriceInfo.dropshipperPrice.SWC
                        : "Na"}
                    </td>
                  </tr>
                  <tr>
                    <td>CNF Price</td>
                    <td className="text-right">
                      {productPriceInfo.seaPrice
                        ? productPriceInfo.seaPrice.cnfPrice
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.finalAirPrice
                        ? productPriceInfo.finalAirPrice.cnfPrice
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.dropshipperPrice
                        ? productPriceInfo.dropshipperPrice.cnfPrice
                        : "Na"}
                    </td>
                  </tr>
                  <tr>
                    <td>Lastmile</td>
                    <td className="text-right">
                      {productPriceInfo.seaPrice
                        ? productPriceInfo.seaPrice.lastMile
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.finalAirPrice
                        ? productPriceInfo.finalAirPrice.lastMile
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.dropshipperPrice
                        ? productPriceInfo.dropshipperPrice.lastMile
                        : "Na"}
                    </td>
                  </tr>
                  <tr>
                    <td>Duty</td>
                    <td className="text-right">
                      {productPriceInfo.seaPrice
                        ? productPriceInfo.seaPrice.duty
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.finalAirPrice
                        ? productPriceInfo.finalAirPrice.duty
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.dropshipperPrice
                        ? productPriceInfo.dropshipperPrice.duty
                        : "Na"}
                    </td>
                  </tr>
                  <tr>
                    <td>COGS</td>
                    <td className="text-right">
                      {productPriceInfo.seaPrice
                        ? productPriceInfo.seaPrice.cogs
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.finalAirPrice
                        ? productPriceInfo.finalAirPrice.cogs
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.dropshipperPrice
                        ? productPriceInfo.dropshipperPrice.cogs
                        : "Na"}
                    </td>
                  </tr>
                  <tr>
                    <td>GST</td>
                    <td className="text-right">
                      {productPriceInfo.seaPrice
                        ? productPriceInfo.seaPrice.gstImpact
                        : "Na"}{" "}
                      (
                      {productPriceInfo.seaPrice
                        ? productPriceInfo.seaPrice.gstImpactPer
                        : "0"}
                      %)
                    </td>
                    <td className="text-right">
                      {productPriceInfo.finalAirPrice
                        ? productPriceInfo.finalAirPrice.gstImpact
                        : "Na"}{" "}
                      (
                      {productPriceInfo.finalAirPrice
                        ? productPriceInfo.finalAirPrice.gstImpactPer
                        : "0"}
                      %)
                    </td>
                    <td className="text-right">
                      {productPriceInfo.dropshipperPrice
                        ? productPriceInfo.dropshipperPrice.gstImpact
                        : "Na"}{" "}
                      (
                      {productPriceInfo.dropshipperPrice
                        ? productPriceInfo.dropshipperPrice
                            .gstImpactPer
                        : "0"}
                      %)
                    </td>
                  </tr>
                  <tr>
                    <td>Weight</td>
                    <td className="text-right">
                      {productPriceInfo.seaPrice
                        ? productPriceInfo.seaPrice.weight
                        : "Na"}{" "}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.finalAirPrice
                        ? productPriceInfo.finalAirPrice.weight
                        : "Na"}{" "}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.dropshipperPrice
                        ? productPriceInfo.dropshipperPrice.weight
                        : "Na"}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>Product Margin</td>
                    <td className="text-right">
                      {productPriceInfo.seaPrice
                        ? productPriceInfo.seaPrice.markup
                        : "Na"}{" "}
                      (
                      {productPriceInfo.seaPrice
                        ? productPriceInfo.seaPrice.markupPer
                        : "0"}
                      %)
                    </td>
                    <td className="text-right">
                      {productPriceInfo.finalAirPrice
                        ? productPriceInfo.finalAirPrice.markup
                        : "Na"}{" "}
                      (
                      {productPriceInfo.finalAirPrice
                        ? productPriceInfo.finalAirPrice.markupPer
                        : "0"}
                      %)
                    </td>
                    <td className="text-right">
                      {productPriceInfo.dropshipperPrice
                        ? productPriceInfo.dropshipperPrice.markup
                        : "Na"}{" "}
                      (
                      {productPriceInfo.dropshipperPrice
                        ? productPriceInfo.dropshipperPrice.markupPer
                        : "0"}
                      %)
                    </td>
                  </tr>
                  <tr>
                    <td>Retailer Price (WO GST)</td>
                    <td className="text-right">
                      {productPriceInfo.seaPrice
                        ? productPriceInfo.seaPrice.priceWithoutGst
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.finalAirPrice
                        ? productPriceInfo.finalAirPrice
                            .priceWithoutGst
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.dropshipperPrice
                        ? productPriceInfo.dropshipperPrice
                            .priceWithoutGst
                        : "Na"}
                    </td>
                  </tr>
                  <tr>
                    <td>Price Including GST</td>
                    <td className="text-right">
                      {productPriceInfo.seaPrice
                        ? productPriceInfo.seaPrice.price
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.finalAirPrice
                        ? productPriceInfo.finalAirPrice.price
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.dropshipperPrice
                        ? productPriceInfo.dropshipperPrice.price
                        : "Na"}
                    </td>
                  </tr>
                  <tr>
                    <td>Consumer Price</td>
                    <td className="text-right">
                      {productPriceInfo.seaPrice
                        ? productPriceInfo.seaPrice.cunsumerPrice
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.finalAirPrice
                        ? productPriceInfo.finalAirPrice
                            .cunsumerPrice
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.dropshipperPrice
                        ? productPriceInfo.dropshipperPrice
                            .cunsumerPrice
                        : "Na"}
                    </td>
                  </tr>
                  <tr>
                    <td>MSRP</td>
                    <td className="text-right">
                      {productPriceInfo.seaPrice
                        ? productPriceInfo.seaPrice.mrp
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.finalAirPrice
                        ? productPriceInfo.finalAirPrice.mrp
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {productPriceInfo.dropshipperPrice
                        ? productPriceInfo.dropshipperPrice.mrp
                        : "Na"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Modal.Body>
          </Modal> */}
    </div>
  );
};

export default MerchantDashboard;


