import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../assets/css/supplier.css";
import { NotificationManager } from "react-notifications";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";

function AddGateEntry() {
  const [courier, setCourier] = useState("");
  const [box, setBox] = useState("");
  const [vehical, setVehical] = useState("");
  const [vehicleName, setVehicleName] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Set the created at date to the current date when the component mounts
    setCreatedAt(new Date().toLocaleString());
  }, []);

  const print = () => {
   
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Gate Entry</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            h1 { text-align: center;}
            h2 { margin-bottom: 20px; }
            table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
            th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
            tr:nth-child(even) { background-color: #f9f9f9; }
          </style>
        </head>
        <body>
        <h1>One Stop Fashions Pvt. Ltd</h1>
          <h2>Gate Entry Details</h2>
          <table>
            <tr>
              <th>Field</th>
              <th>Details</th>
            </tr>
            <tr>
              <td><strong>Created At</strong></td>
              <td>${createdAt}</td>
            </tr>
            <tr>
              <td><strong>Courier</strong></td>
              <td>${courier}</td>
            </tr>
            <tr>
              <td><strong>Total Box/Packet</strong></td>
              <td>${box}</td>
            </tr>
            <tr>
              <td><strong>Vehicle Name</strong></td>
              <td>${vehicleName}</td>
            </tr>
            <tr>
              <td><strong>Vehicle Type</strong></td>
              <td>${vehical}</td>
            </tr>
          </table>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };
  

  const updateCourier = (e) => setCourier(e.target.value);
  const updateBox = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setBox(value);
    } else {
      NotificationManager.error("Box quantity cannot be negative.");
    }
  };

  const updateVehicalType = (e) => setVehical(e.target.value);
  const updateVehicleName = (e) => setVehicleName(e.target.value);

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!courier) {
      isValid = false;
      errors["Courier"] = "Please enter Courier Name.";
    }
    if (!box) {
      isValid = false;
      errors["Box"] = "Please enter boxes quantity.";
    }
    if (!vehical) {
      isValid = false;
      errors["Vehical"] = "Please select a vehicle type.";
    }
    if (!vehicleName) {
      isValid = false;
      errors["VehicleName"] = "Please enter vehicle name.";
    }
    setErrors(errors);
    return isValid;
  };

  const addGateEntry = (e) => {
    e.preventDefault();
    if (validate()) {
      const obj = {
        courier: courier,
        box: box,
        vehical: vehical,
        vehicleName: vehicleName,
      };
      axios
        .post("/wms/GateEntry", obj)
        .then((data) => {
          if (data) {
            NotificationManager.success("Gate Entry Created Successfully");
            setCourier("");
            setBox("");
            setVehicleName("");
            setVehical("");
          }
        })
        .catch((err) => {
          NotificationManager.error(
            "Gate Entry not Created: " + (err.response?.data?.message || err.message)
          );
        });
    }
  };

  return (
    <div className="container-fluid">
      <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
      <div
        className="form-group col-sm-6 col-md-6 col-lg-6"
        style={{ padding: "1em", paddingBottom: "0px", marginTop: "10px" }}
      >
        <Card sx={{ maxWidth: 500 }}>
          <CardActionArea id="cardContentClass">
            <CardContent>
              <form onSubmit={addGateEntry}>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <label
                    htmlFor="courier"
                    style={{ color: "black", fontWeight: "500" }}
                  >
                    Courier
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="Courier"
                    value={courier}
                    onChange={updateCourier}
                    placeholder="Please Enter Courier Name"
                    style={{ border: "1px solid #1D8BF7" }}
                  />
                  <div className="text-danger">{errors.Courier}</div>
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <label
                    htmlFor="box"
                    style={{ color: "black", fontWeight: "500" }}
                  >
                    Total Box/Packet
                  </label>
                  <input
                    style={{ border: "1px solid #1D8BF7" }}
                    type="number"
                    className="form-control"
                    name="box"
                    value={box}
                    onChange={updateBox}
                    placeholder="Please Enter Boxes Quantity"
                  />
                  <div className="text-danger">{errors.Box}</div>
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <label
                    htmlFor="vehicleName"
                    style={{ color: "black", fontWeight: "500" }}
                  >
                    Vehicle Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="vehicleName"
                    value={vehicleName}
                    onChange={updateVehicleName}
                    placeholder="Please enter Vehicle Name"
                    style={{ border: "1px solid #1D8BF7" }}
                  />
                  <div className="text-danger">{errors.VehicleName}</div>
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <label
                    htmlFor="vehical"
                    style={{ color: "black", fontWeight: "500" }}
                  >
                    Vehicle Type
                  </label>
                  <select
                    className="form-control"
                    name="Vehicle Type"
                    value={vehical}
                    onChange={updateVehicalType}
                    style={{ height: 40, border: "1px solid #1D8BF7" }}
                  >
                    <option value="">Please select a vehicle</option>
                    <option value="Gate Entry Inward">Gate Entry Inward</option>
                    <option value="Gate Entry Outward">Gate Entry Outward</option>
                  </select>
                  <div className="text-danger">{errors.Vehical}</div>
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <Button
                    type="submit"
                    onClick={print}
                    className="btn btn-fill"
                    style={{
                      backgroundColor: "rgb(255, 160, 0)",
                      borderColor: "rgb(255, 160, 0)",
                      color: "rgb(255, 255, 255)",
                      fontWeight: "500",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 4,
                      border: "0px",
                      fontSize: "15px",
                    }}
                  >
                    Add Gate entry
                  </Button>
                </div>
              </form>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
      <div className="form-group col-sm-3 col-md-3 col-lg-3"></div>
    </div>
  );
}

export default AddGateEntry;
