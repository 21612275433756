import React, { useState, useEffect } from "react";
import axios from "axios";
import isEmpty from "is-empty";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import { Link, useHistory } from "react-router-dom";
import InWardUpload from "../../views/WmsScreenRoutes/InWardUpload";
import Modal from "react-responsive-modal";
import { NotificationManager } from "react-notifications";
import CustomButton from '../../components/CustomButton/CustomButton';
import "react-step-progress-bar/styles.css";
import "../../index.css";

const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`;

function OrderList() {
  const [csvData, setCsvData] = useState([]);
  
  let history = useHistory();
  let userId = localStorage.getItem("userId");
  
  useEffect(() => {
    // setLoading(false);
    // getOrders(currentPage, showPerPage, {});
  }, []);

  const addCSvProduct = (e) => {
    e.preventDefault();
    
    const scvdata = new FormData();
    scvdata.append('file', csvData[0]);
    scvdata.append('id', localStorage.getItem("userId"));
    if(csvData.length==0){return false;}

    axios
      .post('/wms/uploadRefundCsv', scvdata)
      .then((res) => {
        // console.log(res.data.error);
		// if (res.data.includes('success')) {
		if (res.data.error==0) {
          NotificationManager.success(res.data.message);

        } else {
          res.data.error
            ? NotificationManager.error(res.data.Response.toString())
            : NotificationManager.error('There is a problem with this csv');
        }
      })
      .catch((error) => {
        error
          ? NotificationManager.error(error.toString())
          : NotificationManager.error('There is a problem with this csv');
      });
  };

  return (
    <div class="content">
        <div className="content ">
            <div className="container-fluid">
              <div className="card" style={{padding: "1em"}}>
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <label htmlFor='productImage' style={{fontSize: 15 }}>Upload Inward File</label>
                    <form onSubmit={addCSvProduct} style={{justifyContent: "flex-start", alignItems: "center", margin: "0"}}>
                      <div className='form-group' style={{margin: "0"}}>        
                        <div id='fileContents'></div>
                        <input
                          type='file'
                          className='form-control text-center'
                          name='avatar'
                          onChange={(e) => { 
                          setCsvData(e.target.files);
                          }}
                          encType='multipart/form-data'
                          accept='.csv'
                        />
                        </div>
                        <div className='' style={{margin: "0 0 0 10px"}}>
                        <CustomButton fill type='submit'>
                          Upload Refund
                        </CustomButton>
                        </div>
                    </form>
                    <a href="https://shopperrcdn.shopperr.in/inward/6613cc084184e227e4815e4e2024/10/NR8Kzm_6613cc084184e227e4815e4e.csv" target="_blank">Sample Csv</a>
                  </div>
                </div>
              </div>
            </div>
          
        </div>
     
    </div>
  );
}
export default OrderList;
