import React, { Component } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import 'react-quill/dist/quill.snow.css'; 
import SyncLoader from "react-spinners/SyncLoader"
import _ from 'lodash' 
import 'react-tagsinput/react-tagsinput.css' 
import { NotificationManager } from 'react-notifications'
// import RazorpayInitializer from './RazorpayInitializer';

function loadScript(src) {
	return new Promise((resolve) => {
		const script = document.createElement('script')
		script.src = src
		script.onload = () => {
			resolve(true)
		}
		script.onerror = () => {
			resolve(false)
		}
		document.body.appendChild(script)
	})
}

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`;

// async function displayRazorpay() {
// 	const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

// 	if (!res) {
// 		alert('Razorpay SDK failed to load. Are you online?')
// 		return
// 	}
// 	let obj = {
// 		'id' :  jwt_decode(localStorage.getItem('token')).id,
// 		'amount': this.state.setBalance
// 	}
// 	// const data = await fetch('/shopperrds/razorPay', { method: 'POST', body:JSON.stringify(obj) }).then((t) =>
// 	// 	t.json()
// 	// )

// 	axios.post("/shopperrds/razorPay" , obj)
// 		.then(response => {
// 			console.log(response)
			
// 			const options = {
// 				key: 'rzp_test_BFlq0jWkaRZHbG',
// 				currency: response.data.currency,
// 				amount: response.data.amount.toString(),
// 				order_id: response.data.id,
// 				name: 'Shopperr',
// 				description: 'Thank you for nothing. Please give us some money',
// 				image: 'https://static-sellercentral.shopperr.in/sc-fr/img/brandmark-design-15.png',
// 				// handler: function (response) {
// 				// 	alert(response.razorpay_payment_id)
// 				// 	alert(response.razorpay_order_id)
// 				// 	alert(response.razorpay_signature)
// 				// },
// 				callback_url: "http://3.108.65.154:4006/shopperrds/razorpaysuccess",
// 				prefill: {
// 					name:"Test",
// 					email: 'sdfdsjfh2@ndsfdf.com',
// 					phone_number: '9899999999'
// 				}
// 			}
// 			const paymentObject = new window.Razorpay(options)
// 			paymentObject.open()
// 		})

	
// }

export default class walletSummary extends Component{ 
	constructor(){
		super()
		this.state = {  
			loading: false,
			setBalance : '',
			currentBalance : '',
			afterRechargeBalance : '',
			paynowResponse : false,
			appId : '',
			checkoutAction : '',
			orderId : '',
			orderAmount : '',
			orderCurrency : '',
			orderNote : '',
			customerName : '',
			customerEmail : '',
			customerPhone : '',
			returnUrl : '',
			notifyUrl : '',
			signature : '',
			errors: {},
			Razorpay: null,
			name:"Test"
		}
		
	} 
	setRazorpay = (Razorpay) => {
		this.setState({ Razorpay });
	};

	addBalance = (e) => {
	    this.setState({setBalance : e.target.value})
	    let afterRechargeBalance = parseFloat(this.state.currentBalance) + parseFloat(e.target.value)
	    this.setState({afterRechargeBalance : afterRechargeBalance})  
	}

	componentDidMount(){ 
		this.getMerchantBalance()  
	}

	componentDidUpdate() {
	    if (this.state.paynowResponse) {
	        document.getElementById('cashfreeSubmit').submit();
	    }
	}

	getMerchantBalance(){
		let data = {
			'_id' :  jwt_decode(localStorage.getItem('token')).id
		}
		axios.post("/shopperrds/getMerchant" , data)
		.then(response => {
			if(response.data.error == 0){ 
				// console.log(response)
				this.setState({currentBalance : response.data.response.balance})
				this.setState({afterRechargeBalance : response.data.response.balance})
			}else{
			 	console.log(response);
			} 
		})
	}
	
	async handleSubmit(){
		const data = await new FormData();
		console.log(data);
	}

 	payNow(){ 
		if(this.state.setBalance<5000){
			NotificationManager.error("Minimum amount limit is 5000.")
			return false;
		}
		
		if(this.validate()){
	 		this.setState({loading : true }) 
	 		const obj = {
				'id' :  jwt_decode(localStorage.getItem('token')).id,
				'balance' : this.state.setBalance
			}
			axios.post("/shopperrds/paynow" , obj).then(response => { 
				if(response.data.error == 0){ 
					console.log(response.data.response);
					this.setState({paynowResponse : true }) 
					this.setState({checkoutAction : response.data.response.checkoutAction}) 
					this.setState({appId : response.data.response.appId}) 
					this.setState({orderId : response.data.response.orderId}) 
					this.setState({orderAmount : response.data.response.orderAmount}) 
					this.setState({orderCurrency : response.data.response.orderCurrency}) 
					this.setState({orderNote : response.data.response.orderNote}) 
					this.setState({customerName : response.data.response.customerName}) 
					this.setState({customerEmail : response.data.response.customerEmail}) 
					this.setState({customerPhone : response.data.response.customerPhone}) 
					this.setState({returnUrl : response.data.response.returnUrl}) 
					this.setState({notifyUrl : response.data.response.notifyUrl}) 
					this.setState({signature : response.data.response.signature}) 
				}else{
				 	console.log('response.data.response');
				} 
			})    
		}
 	}
 	
 	validate(){ 
	    let errors = {};
	    let isValid = true;
	  	if (!this.state.setBalance) {
	        isValid = false;
	        errors["balance"] = "Please enter amount.";
	    }  
	    this.setState({
	        errors: errors
	    });
	  
	    return isValid;
	}

	async displayRazorpay() {
		if(this.state.setBalance<5000){
			NotificationManager.error("Minimum amount limit is 5000.")
			return false;
		}

		const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
		
		if (!res) {
			alert('Razorpay SDK failed to load. Are you online?')
			return
		}
		let obj = {
			'id' :  jwt_decode(localStorage.getItem('token')).id,
			'amount': this.state.setBalance
		}
		// const data = await fetch('/shopperrds/razorPay', { method: 'POST', body:JSON.stringify(obj) }).then((t) =>
		// 	t.json()
		// )
		
		// console.log(obj);
		axios.post("/shopperrds/razorPay" , obj)
			.then(response => {
				console.log(response)
				
				const options = {
					key: process.env.RAZORPAY_KEY,
					currency: response.data.currency,
					amount: response.data.amount.toString(),
					order_id: response.data.id,
					name: 'Shopperr',
					description: 'Thank you for nothing. Please give us some money',
					image: 'https://static-sellercentral.shopperr.in/sc-fr/img/brandmark-design-15.png',
					// handler: function (response) {
					// 	alert(response.razorpay_payment_id)
					// 	alert(response.razorpay_order_id)
					// 	alert(response.razorpay_signature)
					// },
					callback_url: process.env.REACT_APP_API_URL+"/shopperrds/razorpaysuccess",
					// prefill: {
					// 	name:"Test",
					// 	email: 'sdfdsjfh2@ndsfdf.com',
					// 	phone_number: '9899999999'
					// }
				}
				const paymentObject = new window.Razorpay(options)
				paymentObject.open()
			})
	
		
	}

	render(){
		return(
			<div style={{ padding : '10px' }}> 
				<SyncLoader css={override} size={15} margin={6} color={"#3E3E3E"} loading={this.state.loading} />
				<div className="container-fluid">
					<div className="card" style={{padding: "1em"}}>
						<div className="row">  
							<div className="col-sm-12 col-md-6 col-lg-12">
								 <div className="card"> 
								 	<div className="card-header"> Recharge E-Wallet </div>
								 	<div className="row form-group">
								 		<div className="col-sm-3 col-md-2 col-lg-2">Current Balance：</div>
								 		<div className="col-sm-4 col-md-4 col-lg-4">Rs . {this.state.currentBalance}</div>
								 	</div> 
								 	<div className="row form-group">
								 		<div className="col-sm-3 col-md-2 col-lg-2">Recharge Amount  </div>
								 		<div className="col-sm-4 col-md-4 col-lg-4">
									 		<input
											    className = 'form-control'
								              	type='text'
								              	name='balance'
								              	value={this.state.setBalance}
								              	onChange={this.addBalance}
												min="5000"
								            />
								            <div className="text-danger">{this.state.errors.balance}</div>
					            		</div>
					            	</div> 
					            	<div className="row form-group">
								 		<div className="col-sm-3 col-md-2 col-lg-2">Balance After Recharge : </div>
								 		<div className="col-sm-4 col-md-4 col-lg-4">Rs . {this.state.afterRechargeBalance}</div> 
								 	</div>
								 	<div className="row form-group"> 
								 		<div className="col-sm-2 col-md-2 col-lg-2"><button className="btn btn-fill" style={{ background: "rgb(255, 160, 0)", borderColor: "rgb(255, 160, 0)", fontSize: "15px", fontWeight: "500"}} onClick={this.payNow.bind(this)}>Pay Cashfree</button></div>
								 		{/* <div className="col-sm-2 col-md-2 col-lg-2"><RazorpayInitializer setRazorpay={this.setRazorpay} /></div> */}
										 <div className="col-sm-2 col-md-2 col-lg-2"><button className="btn btn-fill" style={{ background: "rgb(255, 160, 0)", borderColor: "rgb(255, 160, 0)", fontSize: "15px", fontWeight: "500"}} onClick={this.displayRazorpay.bind(this)}>Pay Razorpay</button></div>
									</div> 
								 </div>
								 
							</div> 
							<div style = {{display : 'none'}}>
								<form id='cashfreeSubmit' action={this.state.checkoutAction} method="post" onSubmit={this.handleSubmit.bind(this)}>
									<input
									    className = 'form-control'
						              	type='hidden'
						              	name='appId'
						              	value={this.state.appId} 
						            />
						            <input
									    className = 'form-control'
						              	type='hidden'
						              	name='orderId'
						              	value={this.state.orderId} 
						            />
						            <input
									    className = 'form-control'
						              	type='hidden'
						              	name='orderAmount'
						              	value={this.state.orderAmount} 
						            />
						            <input
									    className = 'form-control'
						              	type='hidden'
						              	name='orderCurrency'
						              	value={this.state.orderCurrency} 
						            />
						            <input
									    className = 'form-control'
						              	type='hidden'
						              	name='orderNote'
						              	value={this.state.orderNote} 
						            />
						            <input
									    className = 'form-control'
						              	type='hidden'
						              	name='customerName'
						              	value={this.state.customerName}
						            />
						            <input
									    className = 'form-control'
						              	type='hidden'
						              	name='customerEmail'
						              	value={this.state.customerEmail}
						            />
						            <input
									    className = 'form-control'
						              	type='hidden'
						              	name='customerPhone'
						              	value={this.state.customerPhone}
						            />
						            <input
									    className = 'form-control'
						              	type='hidden'
						              	name='returnUrl'
						              	value={this.state.returnUrl}
						            />
						            <input
									    className = 'form-control'
						              	type='hidden'
						              	name='notifyUrl'
						              	value={this.state.notifyUrl}
						            />
						            <input
									    className = 'form-control'
						              	type='hidden'
						              	name='signature'
						              	value={this.state.signature}
						            /> 
						        </form>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
} 