import React, { useState, useEffect } from "react";
import axios from "axios";
import isEmpty from "is-empty";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import { Link, useHistory } from "react-router-dom";
// import jwt_decode from "jwt-decode";

const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`;

function OrderList() {
  const [showUpload, setShowUpload] = useState(true);
  // const [orders, setOrders] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [errormessage, setErrorMessage] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [showPerPage, setShowPerPage] = useState(20);
//   const [selectStore, setSelectStore] = useState("Select Store Name");
  // const [storeData, setStoreData] = useState([]);
  const [searchString, setSearchString] = useState("");
  // const [tempCustomerData, setTempCustomerData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setTodate] = useState("");
  // const [error, setErrors] = useState({});
//   const [defaultStore, setDefaultStore] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState("");
  // const [open, setOpen] = useState(false);
  // const [moreDesc, setMoreDesc] = useState(false);
  // const [msg, setMsg] = useState("");
  // const [singleProduct, setSingleProduct] = useState([]);
  // const [fulfillstatus, setfulfillstatus] = useState();
  // const [percentValue, setpercentValue] = useState();
  // const [percentDate, setpercentDate] = useState("");
  let history = useHistory();
  let userId = localStorage.getItem("userId");
  
  useEffect(() => {
    // let domain = jwt_decode(localStorage.getItem("token")).storeDomain;
    
    setLoading(false);
    // setDefaultStore(domain);
    // setSelectStore(domain);
    getOrders(currentPage, showPerPage, {});
  }, []);
  
  const getOrders = (
    currentPage,
    showPerPage,
    searchparams = {}
  ) => {
    setLoading(true);
    const qs = Object.keys(searchparams)
      .map((key) => `${key}=${searchparams[key]}`)
      .join("&");
    axios
      .get(
        `/wms/dataManagementList?spID=${userId}&page=${currentPage}&limit=${showPerPage}&${qs}`,
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (!res.data.hasOwnProperty("message")) {
          setPageCount(Math.ceil(res.data.count / showPerPage));
          // setOrders({ data: { currentPage: res.data } });
          setPageData(res.data.results);
          setTotalData(res.data.count);
        } else {
          setErrorMessage(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error", err);
      });
  };

  const handlePageClick = (e) => {
    setLoading(true);
    setCurrentPage(e.selected + 1);
    // getOrders(e.selected + 1, showPerPage, {});
    getOrders(e.selected + 1, showPerPage, {
      text: searchString,
      });
  };

  // const handleChange = (e) => {
  //   console.log("Came to handle Change", e.target.value);
  //   document.getElementById("searchOrder").value = "";
  //   // setFromDate("");
  //   // setTodate("");
  //   // setSelectStore(e.target.value);
  //   getOrders(currentPage, showPerPage, {});
  // };

  const handleSearchInvoice = (e) => {
    setSearchString(e.target.value);
  };

  // const handleFromdate = (fromDate) => {
  //   // setFromDate(fromDate);
  //   setErrors({});
  // };

  // const handleToDate = (toDate) => {
  //   setTodate(toDate);
  //   setErrors({});
  // };

  const handleNavigation = () => {
    setShowUpload(!showUpload);
  };
  
  function scanInvoice(){
	console.log(searchString);

	getOrders(1, showPerPage, {
		text: searchString,
	  });
  }


  return (
    <div className="content">
      
        <div className="content ">
          <SyncLoader
            css={override}
            size={15}
            margin={6}
            color={"#3E3E3E"}
            loading={loading}
          />

          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                  <div className="form-group">
                    <label>Search</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Text"
                      onChange={handleSearchInvoice}
                      id="searchOrder"
                      autoComplete="off"
					            onKeyUp={() => scanInvoice()}
                    />
                  </div>
                </div>
                
                <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2">
                  <label>&nbsp;</label>
                  <button
                    style={{
                      background: "rgb(255, 160, 0)",
                      borderColor: "rgb(255, 160, 0)",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                    className="btn btn-primary btn-fill"
                    onClick={() => scanInvoice()}
                  >
                    SEARCH
                  </button>
                </div>
                
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="card " style={{ padding: "1em", "overflow-x": "auto" }}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  getOrders(currentPage, showPerPage, {})
                }
              >
                <div className="form-group">
                  <h5 className="text-bold">
                    Total Inwards:{" "}
                    <span className="text-muted">{totalData}</span>
                    &nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
                  </h5>
                </div>
              </div>
              <table className="table table-hover table-responsive">
                <thead>
                  <tr>
                    {/* <th scope="col" style={{"width":"70%"}}>Supplier Name</th>
                    <th scope="col">OrderId</th> */}
                    <th scope="col">Mkp Orderid</th>
                    <th scope="col">Orderserial</th>
                    <th scope="col">Invoice No</th>
                    <th scope="col">SFC OrderNo</th>
                    <th scope="col">Bafulia Order no</th>
                    <th scope="col">SKU(Code)</th>
                    <th scope="col">Description</th>
                    <th scope="col">Order Qty</th>
                    <th scope="col">Received Qty</th>
                    <th scope="col">Return damaged qty</th>
                    <th scope="col">Order unit price</th>
                    <th scope="col" style={{ maxWidth: '100px' }}>Order total Price</th>
                    <th scope="col">SFC unit Price</th>
                    <th scope="col">SFC total price </th>
                    <th scope="col">SFC RMB price</th>
                    <th scope="col">Safe-T claim Amount</th>
                    <th scope="col">Safe-T claim Approved Amount</th>
                    <th scope="col">Last Mile Traking Id</th>
                    <th scope="col">Last mile Courior Name</th>
                    <th scope="col">Return AWB</th>
                    <th scope="col">Return Type</th>
                    <th scope="col">Account Name</th>
                    <th scope="col">Ship Phone</th>
                    <th scope="col">Weight</th>
                    <th scope="col">Process By</th>
                    <th scope="col">Order status</th>
                    <th scope="col">Order Date</th>
                    <th scope="col">Cancelled by China Date</th>
                    <th scope="col">Invoice Generated Date</th>
                    <th scope="col">Dispatch Date</th>
                    <th scope="col">Delievered date</th>
                    <th scope="col">Return Date</th>
                    <th scope="col">Refund Date</th>
                    <th scope="col">Received Scan Date</th>
                    <th scope="col">Safe-t claim date</th>
                    <th scope="col">Received Safe-t claim date</th>
                    <th scope="col">Declined safe-t claim date</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(pageData) ? (
                    pageData.map((inw) => {
                      let dd = '';
                      let disFormat = '';
                      if(inw.dispatch_date!==null){
                        dd = new Date(inw.dispatch_date);
                        // disFormat = `${dd.getDate()}-${
                        //   dd.getMonth() + 1
                        // }-${dd.getFullYear()}`;
                        disFormat = dd.toUTCString();
                      }else if(inw.dispatch_date!=''){
                        disFormat = '-';
                      }else{
                        disFormat = '-';
                      }

                      let od = '';
                      let ordFormat = '';
                      if(inw.orderDate!==null){
                        od = new Date(inw.orderDate);
                        ordFormat = od.toUTCString();
                      }else if(inw.orderDate!=''){
                        ordFormat = '-';
                      }else{
                        ordFormat = '-';
                      }

                      let rf = '';
                      let refFormat = '';
                      if(inw.refund_date!==null){
                        rf = new Date(inw.refund_date);
                        refFormat = rf.toUTCString();
                      }else if(inw.refund_date!=''){
                        refFormat = '-';
                      }else{
                        refFormat = '-';
                      }

                      let cd = '';
                      let canFormat = '';
                      if(inw.cancel_date!==null){
                        cd = new Date(inw.cancel_date);
                        // canFormat = `${cd.getDate()}-${
                        //   cd.getMonth() + 1
                        // }-${cd.getFullYear()}`;
                        canFormat = cd.toUTCString();
                      }else if(inw.cancel_date!=''){
                        canFormat = '-';
                      }else{
                        canFormat = '-';
                      }

                      let rd = '';
                      let retFormat = '';
                      if(inw.rto_date!==null){
                        rd = new Date(inw.rto_date);
                        // retFormat = `${rd.getDate()}-${
                        //   rd.getMonth() + 1
                        // }-${rd.getFullYear()}`;
                        retFormat = rd.toUTCString();
                      }else if(inw.rto_date!=''){
                        retFormat = '-';
                      }else{
                        retFormat = '-';
                      }

                      let rtd = '';
                      let rtdFormat = '';
                      if(inw.return_date!==null){
                        rtd = new Date(inw.return_date);
                        // rtdFormat = `${rtd.getDate()}-${
                        //   rtd.getMonth() + 1
                        // }-${rtd.getFullYear()}`;
                        rtdFormat = rtd.toUTCString();
                      }else if(inw.return_date!=''){
                        rtdFormat = '-';
                      }else{
                        rtdFormat = '-';
                      }

                      let td = '';
                      let tdFormat = '';
                      if(inw.ticketed_date!==null){
                        td = new Date(inw.ticketed_date);
                        // tdFormat = `${td.getDate()}-${
                        //   td.getMonth() + 1
                        // }-${td.getFullYear()}`;
                        tdFormat = td.toUTCString();
                      }else if(inw.ticketed_date!=''){
                        tdFormat = '-';
                      }else{
                        tdFormat = '-';
                      }

                      let cbd = '';
                      let cbdFormat = '';
                      if(inw.cancel_before_dispatch!==null){
                        cbd = new Date(inw.cancel_before_dispatch);
                        cbdFormat = cbd.toUTCString();
                      }else if(inw.cancel_before_dispatch!=''){
                        cbdFormat = '-';
                      }else{
                        cbdFormat = '-';
                      }

                      let deld = '';
                      let deldFormat = '';
                      if(inw.deleivered_date!==null){
                        deld = new Date(inw.deleivered_date);
                        deldFormat = deld.toUTCString();
                      }else if(inw.deleivered_date!=''){
                        deldFormat = '-';
                      }else{
                        deldFormat = '-';
                      }

                      let rsd = '';
                      let rsdFormat = '';
                      if(inw.received_scan_date!==null){
                        rsd = new Date(inw.received_scan_date);
                        rsdFormat = rsd.toUTCString();
                      }else if(inw.received_scan_date!=''){
                        rsdFormat = '-';
                      }else{
                        rsdFormat = '-';
                      }

                      let scd = '';
                      let scdFormat = '';
                      if(inw.safety_claim_date!==null){
                        scd = new Date(inw.safety_claim_date);
                        scdFormat = scd.toUTCString();
                      }else if(inw.safety_claim_date!=''){
                        scdFormat = '-';
                      }else{
                        scdFormat = '-';
                      }

                      let rscd = '';
                      let rscdFormat = '';
                      if(inw.received_safety_claim_date!==null){
                        rscd = new Date(inw.received_safety_claim_date);
                        rscdFormat = rscd.toUTCString();
                      }else if(inw.received_safety_claim_date!=''){
                        rscdFormat = '-';
                      }else{
                        rscdFormat = '-';
                      }

                      let dscd = '';
                      let dscdFormat = '';
                      if(inw.declined_safety_claim_date!==null){
                        rscd = new Date(inw.declined_safety_claim_date);
                        dscdFormat = rscd.toUTCString();
                      }else if(inw.declined_safety_claim_date!=''){
                        dscdFormat = '-';
                      }else{
                        dscdFormat = '-';
                      }

                      let cch = '';
                      let cchFormat = '';
                      if(inw.cancelled_by_china!==null){
                        cch = new Date(inw.cancelled_by_china);
                        cchFormat = cch.toUTCString();
                      }else if(inw.cancelled_by_china!=''){
                        cchFormat = '-';
                      }else{
                        cchFormat = '-';
                      }
                      
                      let d = '';
                      if(inw.invoiceDate!=null){
                        let d1 = new Date(inw.invoiceDate);
                        d = d1.toUTCString();
                      }else{
                        d = '-';
                      }
                      
                      // let dd = inw.dispatch_date!='undefined' ? new Date(inw.dispatch_date):'0000:00:00';
                      // let disFormat = `${dd.getDate()}-${
                      //   dd.getMonth() + 1
                      // }-${dd.getFullYear()}`;

                      // let cd = new Date(inw.cancel_date);
                      // let rd = new Date(inw.rto_date);
                      // let rtd = new Date(inw.return_date);
                      // let td = new Date(inw.ticketed_date);
                    //   let cr = new Date(inw.created_date);
                    let order_status = '';  
                    if(inw.order_status == 0){
                      order_status = 'Initialize';
                    }else if(inw.order_status == 1){
                      order_status = 'Dispatch from Warehouse';
                    }else if(inw.order_status == 2){
                      order_status = 'In-Transite';
                    }else if(inw.order_status == 3){
                      order_status = 'Deliever';
                    }else if(inw.order_status == 4){
                      order_status = 'RTO';
                    }else if(inw.order_status == 5){
                      order_status = 'Return';
                    }else if(inw.order_status == 6){
                      order_status = 'QC';
                    }else if(inw.order_status == 7){
                      order_status = 'In Stock';
                    }else if(inw.order_status == 8){
                      order_status = 'Damage';
                    }else if(inw.order_status == 9){
                      order_status = 'Refund';
                    }else if(inw.order_status == 10){
                      order_status = 'Invoiced';
                    }else if(inw.order_status == 11){
                      order_status = 'Cancel Before Dispatch';
                    }else{
                      order_status = '';
                    }

                    let processBy = '';  
                    if(inw.processBy == 0){
                      processBy = 'India';
                    }else if(inw.processBy == 1){
                      processBy = 'China';
                    }else if(inw.processBy == 3){
                      processBy = 'Bulk';
                    }else if(inw.processBy == 4){
                      processBy = 'China1';
                    }


                      return (
                        <tr key={inw._id}>
                          {/* <td scope="row"></td> */}
                          {/* <td>{inw.orderId}</td> */}
                          <td>{inw.mkpOrderId}</td>
                          <td>{inw.orderSerial}</td>
                          <td>{inw.inv_number}</td>
                          <td> {inw.cff_orderno} </td>
                          <td>{inw.i_orderno}</td>
                          <td>{inw.code}</td>
                          <td>{inw.description}</td>
                          <td>{inw.qty}</td>
                          <td>{inw.receiveQty}</td>
                          <td>{inw.returnDamagedQty}</td>
                          <td>{inw.orderUnitPrice}</td>
                          <td>{inw.OrderTotalPrice}</td>
                          <td>{inw.unitPrice}</td>
                          <td>{inw.totalPrice}</td>
                          <td>{inw.rmbPrice}</td>
                          <td>{inw.safetyClaimAmt}</td>
                          <td>{inw.safetyClaimApprovedAmt}</td>
                          <td>{inw.trackingId}</td>
                          <td>{inw.courierName}</td>
                          <td>{inw.return_awb}</td>
                          <td>{inw.return_type}</td>
                          <td>{inw.account_name}</td>
                          <td>{inw.ship_phone}</td>
                          <td>{inw.weight}</td>
                          <td>{processBy}</td>
                          <td>{order_status}</td>
                          <td>{ordFormat}</td>
                          <td>{cchFormat}</td>
                          <td>{d}</td>
                          <td>{disFormat}</td>
                          <td>{deldFormat}</td>
                          <td>{rtdFormat}</td>
                          <td>{refFormat}</td>
                          <td>{rsdFormat}</td>
                          <td>{scdFormat}</td>
                          <td>{rscdFormat}</td>
                          <td>{dscdFormat}</td>


                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" className="text-center">
                        <h4>No Data Found</h4>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={10}
                onPageChange={(e) => handlePageClick(e)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
     
    </div>
  );
}
export default OrderList;
