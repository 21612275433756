import React, { useState, useEffect } from "react";
import axios from "axios";
import isEmpty from "is-empty";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import { Link, useHistory } from "react-router-dom";
import InWardUpload from "../../views/WmsScreenRoutes/InWardUpload";
import Modal from "react-responsive-modal";
import { NotificationManager } from "react-notifications";
import CustomButton from '../../components/CustomButton/CustomButton';
import "react-step-progress-bar/styles.css";
import "../../index.css";
import Iframe from '../../components/Iframe.js';

const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`;

function OrderList() {
  const [awb, setAwb] = useState('');
  
  const [iframe, setIframe] = useState('');
  const [displayPdfDiv, setDisplayPdfDiv] = useState('block');
  
  
  let history = useHistory();
  let userId = localStorage.getItem("userId");
  
  useEffect(() => {
    // setLoading(false);
    // getOrders(currentPage, showPerPage, {});
  }, []);

  
 const today = new Date();
  let date = ("0" + today.getDate()).slice(-2);
  let month = ("0" + (today.getMonth() + 1)).slice(-2);
  let year = today.getFullYear();
  const currentDate = year + "-" + month + "-" + date;
	let cnt2 = 1;
  function confirmBarcode(){

    if(cnt2>1){
      return false;
    }

    if(awb==''){
      NotificationManager.error(`Please input text.`);
      return;
    }
    
    const obj = {
		ordersCode: awb
	}; 
    axios.post("/wms/re_print/",obj)
		.then(resData => {
		console.log(resData);
	  setAwb('');
	  
	 

	  if(resData.data.pin==1){
			if (window.confirm(resData.data.pinmsg)) {
			  // Save it!console.log('Thing was saved to the database.');
				  setIframe(process.env.REACT_APP_API_URL+"/reprint/"+currentDate+'/'+resData.data.pdfFile);
				//setIframe(resData.data.pdfFile);
				let obj = {
				  filePath : process.env.REACT_APP_API_URL+"/reprint/"+currentDate+'/'+resData.data.pdfFile
				}
				
				axios.post("/wms/unlinkpdf/",obj)
					.then(resData => {

				});
			} else {
			  // Do nothing!console.log('Thing was not saved to the database.');
			}
        } else {
			 setIframe(process.env.REACT_APP_API_URL+"/reprint/"+currentDate+'/'+resData.data.pdfFile);
				//setIframe(resData.data.pdfFile);
				let obj = {
				  filePath : process.env.REACT_APP_API_URL+"/reprint/"+currentDate+'/'+resData.data.pdfFile
				}
				
				axios.post("/wms/unlinkpdf/",obj)
					.then(resData => {

				});
        }
        
     
		});

    cnt2++;
  }
  return (
    <div className="content">
        <div className="content">
          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <div style={{ cursor: "pointer" }} >
                <div className="form-group">
                      <div className='card ' style={{ marginTop: 0 , padding: '0px 10px' }}>
                        <div className='row'>
                          <div className='col-md-3'>
                            <label htmlFor='awb_scan'>Scan WSTK Or AWB Number</label>
                          </div>
                          <div className='col-md-4'>
                              <div className='form-group'>
                                  <input type='text' className='form-control' name="awb_scan" id='awb_scan' value={awb} onChange={(e) => setAwb(e.target.value)}  />
                              </div>
                          </div>
                          <div className='col-md-2'>
                              <button
									style={{
									  background: "rgb(255, 160, 0)",
									  borderColor: "rgb(255, 160, 0)",
									  fontSize: "12px",
									  fontWeight: "300",
									}}
									className="btn btn-primary btn-fill"
									onClick={() => confirmBarcode()}
								  >
									Scan
							  </button>
                          </div>
                        </div>
                      </div>
                 </div>
              </div>
             <div id="pdfIframeWrap" style={{width:"10cm", height:"15.5cm", border:"2px solid #ccc", background:"#fff", margin:"5px 0"}}>
              <Iframe source={iframe} />
            </div>
            </div>
            
          </div>
        </div>
     
    </div>
  );
}
export default OrderList;
