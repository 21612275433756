import React, { useState, useEffect } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Modal from "react-responsive-modal";
import { NotificationManager } from "react-notifications";
import Card from "../../components/Card/Card.jsx";
import "../../assets/css/productList.css";
import ReactPaginate from 'react-paginate'
import _ from 'lodash'
import CustomButton from '../../components/CustomButton/CustomButton';
const CsvProduct = () => {
  const [productItems, setProductItems] = useState([]); 
  const token = localStorage.getItem("token");
  const decode = jwt_decode(token);
  const [csvData, setCsvData] = useState([]);
  const [csvNewData, setCsvNewData] = useState([]);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [warranty, setWarranty] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [code, setCode] = useState("");
  const [itemId, setItemId] = useState("");
  const [limit, setlimit] = useState(100);
  const [page, setPage] = useState(1);
  const [pageUp, setPageUp] = useState([]);
  const [selectedPage, setSelectedPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageCountIm, setPageCountIm] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageIm, setCurrentPageIm] = useState(1);
  const [showPerPage, setShowPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [supllierList, setSupllierList] = useState([]);
  const [csvResults, setCsvResults] = useState([]);

  const modalStyle = {
    margin: "auto",
    position: "relative",
  };

  useEffect(() => {
	getProductData();
    getSupplierList();
  }, []);
	
	const getSupplierList = () =>{
		axios.get("/admin/supplierList")
		.then(response => {
			setSupllierList(response.data)
		})
	};
	
	const handlePageClick = (e) => { 
        getcsvData((e.selected + 1), showPerPage) 
    };
    
    const handlePageClickIm = (e) => { 
        getcsvImData((e.selected + 1), showPerPage) 
    }; 
    
    const getcsvData = (currentPage,showPerPage) =>{
		let suppId = document.getElementById("supplierList").value 
		if(suppId == 'Select Supplier'){
			suppId = '60b486a0c4ab48a129b2120e';
		}
		axios.get("/admin/getUploadProductCsv?page="+currentPage+"&limit="+showPerPage+"+&id="+suppId)
		.then(resData => {
			let count = resData.data.items;
			let rows = []
            for (var i = 0; i < Math.ceil(resData.data/limit); i++) {
                rows.push(i+1)
            } 
            setPage(rows)
            setPageCount(Math.ceil(resData.data.totalCount / showPerPage))
            setCurrentPage(currentPage)
            setCsvResults(resData.data.items);
		})
    };
    
    const getcsvImData = (currentPageIm,showPerPage) =>{
		let suppId = document.getElementById("supplierList").value 
		if(suppId == 'Select Supplier'){
			suppId = '60b486a0c4ab48a129b2120e';
		}
		axios.get("/admin/getUploadUpdateProductCsv?page="+currentPageIm+"&limit="+showPerPage+"+&id="+suppId).then((products) => {
			setProductItems(products.data.items);
			let items = []
			for (var i = 0; i < Math.ceil(products.data.product/limit); i++) {
				items.push(i+1)
			} 
			setPage(items)
            setPageCountIm(Math.ceil(products.data.totalCount / showPerPage))
			setCurrentPageIm(currentPageIm)
        });
    };
    
  const getProductData = () => {
    let suppId = document.getElementById("supplierList").value 
    if(suppId == 'Select Supplier'){
		suppId = '60b4ba883d4df5db306b6b23';
    }
    axios.get("/admin/getUploadUpdateProductCsv?page="+currentPage+"&limit="+showPerPage+"+&id="+suppId).then((products) => {
		setProductItems(products.data.items);
		let items = []
		for (var i = 0; i < Math.ceil(products.data.product/limit); i++) {
			items.push(i+1)
		} 
		setPage(items)
		setPageCountIm(Math.ceil(products.data.totalCount / showPerPage))
		setCurrentPageIm(currentPage)
		 
	});
	axios.get("/admin/getUploadProductCsv?page="+currentPage+"&limit="+showPerPage+"+&id="+suppId)
		.then(resData => {
		let count = resData.data.items;
		let rows = []
		for (var i = 0; i < Math.ceil(resData.data/showPerPage); i++) {
			rows.push(i+1)
		} 
		setPageUp(rows)
		setPageCount(Math.ceil(resData.data.totalCount / showPerPage))
		setCurrentPage(currentPage)
		setCsvResults(resData.data.items);
	})
	
  };

  const updateProduct = (item) => {
    console.log("updateProduct", item._id);
    setName(item.name);
    setPrice(item.price);
    setQuantity(item.quantity);
    setWarranty(item.warranty);
    setDescription(item.description);
    setCategory(item.category);
    setCode(item.code);
    setItemId(item._id);
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const deleteProduct = (item) => {
    console.log("delete" + item._id);
    axios.delete("/api/csvProduct/" + item._id).then((data) => {
      if (data) {
        NotificationManager.success("Product Deleted");
        getProductData();
      }
      else {
        NotificationManager.error("Something Went Wrong")
      }
    });
  };

  const updateProductItem = (e) => {
    e.preventDefault();
    const object = {
      _id: itemId,
      name: name,
      price: price,
      quantity: quantity,
      warranty: warranty,
      description: description,
      category: category,
      code: code,
    };
    console.log(object);
    axios
      .patch("/api/product/update", object)
      .then((data) => {
        if (data) {
          NotificationManager.success("Product Updated Successfully"); 
          setName("");
          setPrice("");
          setQuantity("");
          setWarranty("");
          setDescription("");
          setCategory("");
          setCode("");
          setOpen(false);
          getProductData();
        }
      })

      .catch((err) => {
        NotificationManager.error("Product Updated Error");
      });
  };

//Add Product from CSV File
  const addCSvProduct = (e) => {
    e.preventDefault();
    let suppId = document.getElementById("supplierList").value 
    if(suppId == "" || suppId == 'Select Supplier'){
		alert('Please select supplier from supplier list!')
		return false;
    }
    const scvdata = new FormData();
    scvdata.append('file', csvData[0]);
    scvdata.append('supplier_id', suppId);
    console.log(scvdata)
    axios
      .post('/admin/uploadProductCsv', scvdata)
      .then((res) => {
        if (res.data.includes('success')) {
          NotificationManager.success('File uploaded Successfully');
        } else {
          res.data.error
            ? NotificationManager.error(res.data.error.toString())
            : NotificationManager.error('There is a problem with this csv');
        }
      })
      .catch((error) => {
        error
          ? NotificationManager.error(error.toString())
          : NotificationManager.error('There is a problem with this csv');
      });
  };
  
  

// Download Product CSV File
const downloadProductCsv = (e) => {
    e.preventDefault();
    let suppId = document.getElementById("supplierList").value 
    if(suppId == "" || suppId == 'Select Supplier'){
		alert('Please select supplier from supplier list!')
		return false;
    }
    const csvForm = new FormData();
    csvForm.append('page', 1);
    csvForm.append('limit', 50);
    axios.post('/admin/downloadProductCsv/'+suppId , csvForm).then((res) => {
        if (res.data.includes('success')) {
            setSelectedPage("")
            NotificationManager.success('File Download Successfully');
        } else {
            res.data.error
            ? NotificationManager.error(res.data.error.toString())
            : NotificationManager.error('There is a problem with this csv');
        }
    }).catch((error) => {
        error
        ? NotificationManager.error(error.toString())
        : NotificationManager.error('There is a problem with this csv');
    });
};   

//Upload product CSV
const uploadProductCsv = (e) => {
	e.preventDefault() 
	let suppId = document.getElementById("supplierList").value 
    if(suppId == "" || suppId == 'Select Supplier'){
		alert('Please select supplier from supplier list!')
		return false;
    }
	const data = new FormData();
	data.append('file', csvNewData[0]);
	data.append('supplier_id', suppId);
	//console.log(data);return false;
	if(csvData){ 
		axios.post('/admin/uploadProductCsv', data)
		.then(result => {
			if(result.data.error == 0){
				NotificationManager.success(result.data.Response)
			}else{
				NotificationManager.success(result.data.Response)
			} 
		})
		.catch(err => {
			document.getElementById("uploadProductCsv").disabled = false 
			NotificationManager.error('Something went wrong, please try again later.')
		}) 
	}else{
		NotificationManager.success('Select File.')
	}
};


return (
    <div className="mainBox">
    <br />
    <div className="container-fluid">
		<div className="card" style={{padding: "1em"}}>
			<div className="row">
			
				<div className="col-sm-5 col-md-5 col-lg-5">
					<select className="form-control" id="supplierList" onChange={getProductData}>
						<option value="Select Supplier" >Select Supplier</option>
						{
							supllierList.map((item, key) => {
								return(
									<option key={key} value={item._id}>{item.firstName}</option>
								)
							})
						}
					</select>
				</div>
				<div className="col-sm-2 col-md-2 col-lg-2">
					<form onSubmit={downloadProductCsv}>
					<div className="col-sm-2 col-md-2 col-lg-2">
						<div className='form-group'>
							{ (page.length > 0) ? 
								  <div>
								  <label style={{fontSize: 10 }} htmlFor='productTitle'>
									  Page <span className="custom-tooltip" data-tooltip="CSV file will have 500 products per page.">
										  <i className="fa fa-question-circle" aria-hidden="true"></i>
									  </span>
								  </label>
									<select className='form-control'   onChange={e => { setSelectedPage(e.target.value); }} >
										{page.map((item, key) => {  
											return (
												<option value= {item} >{item}</option>
											)
										})}
									</select> 
									</div>
									: '' 
								}
						</div>
					</div>
					<div className="col-sm-1 col-md-1 col-lg-1">
						<div className='form-group'>
							<div className='' style={{margin: "0 0 0 10px"}}>
							<CustomButton fill type='submit'>
								Download Product
							</CustomButton>
							</div>
						  </div>
					</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<div className="container-fluid">
		<div className="card" style={{padding: "1em"}}>
			<div className="row">
				<div className="col-sm-6 col-md-6 col-lg-6">
					<label htmlFor='productImage' style={{fontSize: 15 }}>Update CSV File</label>
					<form onSubmit={addCSvProduct} style={{justifyContent: "flex-start", alignItems: "center", margin: "0"}}>
						<div className='form-group' style={{margin: "0"}}>        
							<div id='fileContents'></div>
							<input
							  type='file'
							  className='form-control text-center'
							  name='avatar'
							  onChange={(e) => { 
								setCsvData(e.target.files);
							  }}
							  encType='multipart/form-data'
							  accept='.csv'
							/>
						  </div>
						  <div className='' style={{margin: "0 0 0 10px"}}>
							<CustomButton fill type='submit'>
							  Update Product
							</CustomButton>
						  </div>
					</form>
					<a href="https://static-sellercentral.shopperr.in/import_export/2021/10/upload8eQonE_605194af3da40def6172dfac.csv" target="_blank">Sample Csv</a>
				</div>
				<div className="col-sm-6 col-md-6 col-lg-6">
					<label htmlFor='productImage' style={{fontSize: 15 }}>Product Csv upload &nbsp;
						<span className="custom-tooltip" data-tooltip="Clear and informative csv, which allow clear recognition of the product">
							<i className="fa fa-question-circle" aria-hidden="true"></i>
						</span>
					</label>
					<form onSubmit={uploadProductCsv} style={{justifyContent: "flex-start", alignItems: "center", margin: "0"}}>
						<div className="form-group" style={{margin: "0"}}>
							<input
									type='file'
									id="productCsv" style={{height:"40px"}}
									onChange={(e) => {  
									setCsvNewData(e.target.files)
									}}  
									encType='multipart/form-data'
									accept='.csv'
								/> 
						</div>  
						<div className='' style={{margin: "0 20px"}}>
							<CustomButton fill type='submit' id="uploadProductCsv" >
								Upload Products
							</CustomButton> 
						</div>
					</form>
					<a href='https://static-sellercentral.shopperr.in/product/60deae77da7e45105c54529e2021/7/fBKRUV_60deae77da7e45105c54529e.csv' target="_blank">Sample CSV</a>
				</div>
			</div>
		</div>
	</div>
	<div className="container-fluid">
		<div className="card" style={{padding: "1em"}}>
			<div className="row">
				<div className="col-sm-6 col-md-6 col-lg-6">
					<div className="table-responsive">
						<p>CSV File Update(SKU) Export / Import List</p>
						<table className="table table-hover ProductListTable">
							<thead>
								<tr className="d-flex">
									<th className='text-center' scope="col" >S.NO.</th>
                                    <th className='text-center' scope="col" >Status</th>
									<th className='text-center' scope="col" >Action</th>  
									<th className='text-center' scope="col" >Created Date</th>  
								</tr>
							</thead>
							<tbody>
								{
								   !_.isEmpty(productItems)
									?  
										productItems.map((item, index) => {
											const {_id, status , action  , date} = item
											return(
												<tr key={index} className="d-flex">
													<td className='text-center'>{((currentPageIm - 1) * showPerPage) + (index + 1)}</td>
                                                    <td className='text-center' style={{color: (item.status == 'Success' ? 'Green' : 'orange' ) }}> {status||"NA"}</td>
													<td className='text-center'><a href={ action }>Import</a></td>
													<td className='text-center'>{date}</td> 
												</tr>
											)
										})
									:
										<tr>
											<td colSpan="5" className="text-center" style={{fontSize: "20px"}}>No Data Found</td>
										</tr>
								}
							</tbody>
						</table>
					</div>
					<ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={pageCountIm}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClickIm.bind(this)}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                </div>
				<div className="col-sm-6 col-md-6 col-lg-6">
					<div className="table-responsive">
							<p>CSV File Add New(SKU) Import List</p>
                            <table className="table table-hover ProductListTable">
                                <thead>
                                    <tr className="d-flex">
										<th className='text-center' scope="col" >S.NO.</th>
                                        <th className='text-center' scope="col" >Status</th>
                                        <th className='text-center' scope="col" >Action</th>  
                                        <th className='text-center' scope="col" >Logs</th>  
                                        <th className='text-center' scope="col" >Created Date</th>  
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                       !_.isEmpty(csvResults)
                                        ?  
                                            csvResults.map((item, index) => {
                                                const {_id, status , action , resUrl , date} = item
                                                return(
                                                    <tr key={index} className="d-flex">
														<td className='text-center'>{((currentPage - 1) * showPerPage) + (index + 1)}</td>
                                                        <td className='text-center' style={{color: (item.status == 'Success' ? 'Green' : 'orange' ) }}> {status||"NA"}</td>
                                                        <td className='text-center'><a href={ action }>Import</a></td>
                                                        <td className='text-center'><a href={ resUrl }>Download</a></td>
                                                        <td className='text-center'>{date}</td> 
                                                    </tr>
                                                )
                                            })
                                        :
                                            <tr>
                                                <td colSpan="5" className="text-center" style={{fontSize: "20px"}}>No Data Found</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
						<ReactPaginate
                                previousLabel={'<'}
                                nextLabel={'>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick.bind(this)}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
				</div>
			</div>
		</div>
      </div>
    </div>
  );
};

export default CsvProduct;
