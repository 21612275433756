import React, { useState, useEffect, useRef } from "react";
import CsvDownloader from "react-csv-downloader";
import { Grid, Row, Col, Table } from "react-bootstrap";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import Card from "../../components/Card/Card.jsx";
import moment from "moment";
import { Link } from "react-router-dom";
import _ from "lodash";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import { getPriceFormatter } from "../../helpers/index.js";

const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`;
const SupplierList = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [withoutSortsuppliers, setwithoutSortsuppliers] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("");
  const [tempSupplier, setTempSupplier] = useState("");
  const [sRevenue, setSRevenue] = useState();
  const [sOrder, setSOrder] = useState();
  const [productCount, setProductCount] = useState();
  const [sort, setSort] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [showPerPage, setshowPerPage] = useState(30);
  const [pageCount, setpageCount] = useState(0);
  const [productTotalCount, setproductTotalCount] = useState(0);
  const [show, setShow] = useState(false);
  const deleteItem = useRef();
  let checkArray = [];

  useEffect(() => {
    getSupplierData(currentPage, showPerPage);
    localStorage.setItem("editSupplierFrom", "");
  }, []);

  const getSupplierData = async (page, perPage) => {
    try {
      const response = await axios.get(`/admin/suppliernewData?page=${page}&limit=${perPage}&category=supplier`);
      console.log("API Response:", response.data);
      setSuppliers(response.data.data);
      setwithoutSortsuppliers(response.data.data);
      setproductTotalCount(response.data.totalCount);
      setpageCount(Math.ceil(response.data.totalCount / perPage));
      setcurrentPage(page);
    } catch (error) {
      console.error("Error fetching supplier data", error);
    }
  };
  
  
  const handleSearchSKU = () => {
    if (_.isEmpty(search)) {
      NotificationManager.error("Enter Sku");
    } else {
      axios.get(`/admin/supplierById?supplierId=${search}`).then((res) => {
        setSuppliers(res.data.data);
        setwithoutSortsuppliers(res.data.data);
        setproductTotalCount(res.data.totalCount);
        setpageCount(Math.ceil(res.data.totalCount / showPerPage));
        setcurrentPage(currentPage);
      });
    }
  };

  const handlePageClick = (e) => {
    getSupplierData(e.selected + 1, showPerPage);
  };
  const storeUniqueId = (id) => {
    localStorage.setItem("code", id);
    localStorage.setItem("editSupplierFrom", "admin");
  };

  const handleDelete = (item) => {
    deleteItem.current = item;
    setShow(true);
  };
  const deleteSupplier = () => {
    let item = deleteItem.current;

    let id = item._id;
    axios.delete("/admin/supplierDel/" + id).then((res) => {
      try {      
        if (res.data.includes("success")) {
          setShow(false);
          NotificationManager.success("Supplier deleted successfully");
          getSupplierData(currentPage, showPerPage);
        }
      } catch (error) {
        NotificationManager.error("Something Went Wrong.");
      }
    });
  };

  const filterItems = suppliers.filter((plist) => {
    return plist.supplier_id.toLowerCase().includes(search.toLowerCase());
  });

  const handleSort = (e) => {
    e.preventDefault();

    if (e.target.value === "product") {
      const data = [...withoutSortsuppliers];
      data.sort((a, b) => parseFloat(b.products) - parseFloat(a.products));

      setSuppliers(data);
    } else if (e.target.value === "order") {
      const data = [...withoutSortsuppliers];
      data.sort((a, b) => parseFloat(b.orders) - parseFloat(a.orders));
      setSuppliers(data);
      console.log({ suppliers });
    } else if (e.target.value === "revenue") {
      const data = [...withoutSortsuppliers];
      setSuppliers(
        data.sort((a, b) => parseFloat(b.revenue) - parseFloat(a.revenue))
      );
    } else if (e.target.value === "nothing") {
      setSuppliers(withoutSortsuppliers);
    }
  };

  const triggerHanleSearch = (e) => {
    e.persist();
    if (e.key === "Enter") {
      handleSearchSKU();
    }
  };

  const handleClose = () => setShow(false);

  return (
    <div className="content">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Supplier</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete ?</Modal.Body>
        <Modal.Footer>
          <button
            style={{ color: "white", background: "green" }}
            onClick={() => setShow(false)}
          >
            No
          </button>
          <button
            style={{ color: "white", background: "red", marginTop: "8px" }}
            onClick={() => deleteSupplier()}
          >
            Yes
          </button>
             
        </Modal.Footer>
      </Modal>
      <div className="info text-center" style={{ color: "red" }}>
        {status}
      </div>
      <div className="row" style={{ paddingLeft: "15px" }}>
       
        <div className="col-md-4" >
          <input
            type="search"
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={triggerHanleSearch}
            className="primary"
            placeholder="Search..."
            style={{ height: "40px", width: "100%",borderRadius:"5px",border:"1px solid rgb(29, 139, 247)" }}
          />
        </div>
        
        <div className="col-md-4">
        <select className="form-control" onChange={(e) => handleSort(e)} style={{ borderRadius:"5px",border:"1px solid rgb(29, 139, 247)" }}>
          <option value="nothing">Sort By</option>
          <option value="product"> Product</option>
          <option value="order">Order</option>
          <option value="revenue">Revenue</option>
        </select>
      </div>
      <div className="col-md-1"></div>
        <div
          className="col-md-2"
          style={{
            backgroundColor: "rgb(29, 139, 247)",
            color: "rgb(255, 255, 255)",
            fontWeight: "500",
           
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 5,
            border: "0px",
            fontSize: "15px",
          }}
        >
          <CsvDownloader
            filename="AdminOrderDetails"
            separator=","
            wrapColumnChar="'"
            datas={suppliers}

          >
            <div>Download Csv File</div>
          </CsvDownloader>
        </div>
        <div className="col-md-1"></div>
      </div>
      <br />
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Supplier List"
              category={"Total Suppliers :" + productTotalCount}
              ctTableFullWidth
              ctTableResponsive
              content={
                <div className="table-responsive">
                  &nbsp;
                  <i
                    onClick={() => {
                    getSupplierData(1, showPerPage, {});
                    }}
                    style={{cursor:"pointer",position:"relative",bottom:"30px",left:"12%"}}
                    className="fa fa-refresh"
                    aria-hidden="true"
                  ></i>
                  <Table striped hover size="sm">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>username</th> 
                        <th>Email</th>
                        <th>Total no. of Products</th>
                        <th>Total no. of Orders</th>
                        <th>total Revenue</th>
                      </tr>
                    </thead>
                    <tbody>
                      {suppliers.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              {showPerPage * (currentPage - 1) + (key + 1)}
                            </td>
                            <td>{item.supplier_id}</td>
                            <td>{item.email}</td>
                            <td>
                              { 
                                 item.totalProducts 
                              }
                            </td>
                            
                            <td>
                           
                              {item.totalOrders}    
                            
                            </td>

                            <td> {getPriceFormatter(item.totalRevenue)}</td>
                            <td>
                              <Link to="/admin/editSupplier">
                                <button
                                  className="btn btn-fill"
                                  style={{
                                    backgroundColor: "#1D8BF7",     
                                    color: "rgb(255, 255, 255)",
                                    fontWeight: "500",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: 4,
                                    border: "0px",
                                    fontSize: "15px",
                                  }}
                                  onClick={() => storeUniqueId(item._id)}
                                >
                                  Edit
                                </button>
                              </Link>
                            </td>
                            <td>
                              <button
                                className="btn btn-fill"
                                style={{
                                  backgroundColor: "rgb(254, 15, 15)",
                                  color: "rgb(255, 255, 255)",
                                  fontWeight: "500",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: 4,
                                  border: "0px",
                                  fontSize: "15px",
                                }}
                                onClick={() => handleDelete(item)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  {!_.isEmpty(suppliers) && (
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={3}
                      pageRangeDisplayed={10}
                      onPageChange={(e) => handlePageClick(e)}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  )}
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};
export default SupplierList;
