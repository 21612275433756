import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import isEmpty from "is-empty";
import ReactPaginate from "react-paginate";
import SyncLoader from "react-spinners/SyncLoader";
import { Link, useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { NotificationManager } from "react-notifications";
import Iframe from '../../components/Iframe.js';


import Modal from "react-responsive-modal";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Button } from "react-bootstrap";
import "../../index.css";

import CustomButton from '../../components/CustomButton/CustomButton';


const override = `
    position: fixed;
    display: block;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    transform: translate(-50%, -50%);
    z-index: 1;
`;

function OrderList() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const [barcodeNumber, setBarcodeNumber] = useState("");
  const [offshelfSearch, setOffshelfSearch] = useState("");
  const [offshelfNumber, setOffshelfNumber] = useState("");
  const [orderCount, setorderCount] = useState("0");
  const [scanOrderCount, setScanOrderCount] = useState("0");
  const [scanOrderQty, setScanOrderQty] = useState("0");
  const [rescanList, setRescanList] = useState([]);
  const [scannedProducts, setScannedProducts] = useState([]);
  const [scannedSkuTotal, setScannedSkuTotal] = useState([]);
  const [skuInOrders, setSkuInOrders] = useState("123");
  const [oldProductId, setOldProductId] = useState("-1");
  const [scanedOrders, setScanedOrders] = useState([]);
  const [needScanedOrdersSum, setNeedScanedOrdersSum] = useState({});
  const [compareOrderArr, setCompareOrderArr] = useState([]);
  
  const [orderCode, setOrderCode] = useState('');
  const [ordersArr, setOrdersArr] = useState({});
  const [iframe, setIframe] = useState('');
  const [displayPdfDiv, setDisplayPdfDiv] = useState('none');
  const [orderTotal, setOrderTotal] = useState('');

  let history = useHistory();
  let userId = localStorage.getItem("userId");

  const today = new Date();
  let date = ("0" + today.getDate()).slice(-2);
  let month = ("0" + (today.getMonth() + 1)).slice(-2);
  let year = today.getFullYear();
  const currentDate = year + "-" + month + "-" + date;

  const inputRef = useRef(null);
  const inputRef2 = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  
  useEffect(() => {
 
    setLoading(false);
    // setDefaultStore(domain);
    // setSelectStore(domain);
    // getOrders(currentPage, showPerPage, {});
  }, []);

  useEffect(() => {
    // This will run whenever skuInOrders changes
    // console.log("skuInOrders changed:", skuInOrders);
   
  }, [skuInOrders]);
  
  
  const handleSearchInvoice = (e) => {
    setOffshelfSearch(e.target.value);
  };

  const handleSearchBarcode = (e) => {
    setBarcodeNumber(e.target.value);
    setOrdersArr([e.target.value]);
  };
  
  const handleChange = (event) => {
    const { value } = event.target;
    // setSelectedOrder(value);
    if (value !== '') {
      showSelectedOrder(value);
    }
  };

  const handlePrint = (iframeUrl) => {
    
    const printWindow = window.open(iframeUrl, '_blank');

    printWindow.addEventListener('load', () => {
      printWindow.print();
    });
  };
  let cnt = 1;
  function scanOffshelf(event){
    // console.log(offshelfNumber);
    if(cnt>1){
      return false;
    }
    // console.log(event.type)
    
    // if(offshelfSearch.length<13){
    //   return false;
    // }
    axios.get("/wms/indiaOffshelfScan/"+offshelfSearch)
		.then(resData => {
			setDisplayPdfDiv('none');
      if(resData.data.error==1){
        setorderCount(0);
        setOffshelfNumber("");
        NotificationManager.error(resData.data.response);
      }else{
        setOffshelfNumber(offshelfSearch);
        setorderCount(resData.data.op_order_cnt);
        setScanOrderCount(resData.data.scanedOrdersCode);
        setOrderTotal(resData.data.ItemsTotal);
        setScanOrderQty(resData.data.scanedQty);
        // NotificationManager.success(resData.data.response);

        if (inputRef2.current) {
          inputRef2.current.focus();
        }
      } 

		});

    cnt++;
  }

  let cnt2 = 1;
  function confirmBarcode(){

    if(cnt2>1){
      return false;
    }

    if(barcodeNumber==''){
      // NotificationManager.error(`Please input barcode.`);
      return;
    }
    // if(barcodeNumber.length<13){
    //   return false;
    // }
    
    if(offshelfSearch==''){
      NotificationManager.error('Please input Off-Shelf Number.');
      return false;
    }

    // orderCount;
    // scanOrderCount;
    if(orderCount == scanOrderCount && orderCount>0){
        NotificationManager.success('Completed Scanning.');
        setOffshelfNumber(0);
        setorderCount(0);
        return false;
      
    }

    if(rescanList.length>0){
      return false;
    }

    /*if (scannedProducts.includes(barcodeNumber)) {
      NotificationManager.error(`${barcodeNumber} Scanned!`);
      return;
    }*/
    
    const scandata = new FormData();
    scandata.append('offshelfSearch', offshelfSearch);
    scandata.append('barcodeNumber', barcodeNumber);
    scandata.append('orderTotal', orderTotal);

    axios.post("/wms/indiaConfirmBarcode/",scandata)
		.then(resData => {
			
      setBarcodeNumber('');
      if(resData.data.error==1){
        // setorderCount(0);
        // setOffshelfNumber("");
        NotificationManager.error(resData.data.response);
      }else{
        
        
        setOffshelfNumber(offshelfSearch);
        // setorderCount(resData.data.op_order_cnt);

        // console.log(process.env.REACT_APP_API_URL+"/"+currentDate+'/'+resData.data.pdfFile);
        setIframe(process.env.REACT_APP_API_URL+"/labels/"+currentDate+'/'+resData.data.pdfFile);
        setDisplayPdfDiv('block');
        setScanOrderCount(resData.data.scanedOrdersCode);
        setScanOrderQty(resData.data.scanedQty);


        if(resData.data.scanedOrdersCode==orderTotal){
          setOffshelfSearch('')
        }
        

        setScannedProducts([...scannedProducts, barcodeNumber]);
        // setSkuInOrders(345);
        // alert(1); 

        let product_sku = resData.data.product_sku;
        // setSkuInOrders(product_sku); 

        setTimeout(() => {
          // handlePrint(process.env.REACT_APP_API_URL+"/labels/"+currentDate+'/'+resData.data.pdfFile);
          // unlink_file(resData.data.pdfFile);
        }, 3000);
        
        
        setScannedSkuTotal(prevState => {
          const updatedSkuTotal = { ...prevState };
          if (updatedSkuTotal[product_sku]) {
            updatedSkuTotal[product_sku] += 1;
          } else {
            updatedSkuTotal[product_sku] = 1;
          }
          return updatedSkuTotal;
        });

        // console.log(product_sku);
        
        // setSkuInOrders(345);
        let result = findOrdersByProductID(product_sku);
        
      }
      
		});

    cnt2++;
  }

  function unlink_file(file){
    
    let obj = {
      filePath : file
    }
    
    axios.post("/wms/unlinkpdf/",obj)
		.then(resData => {

    });
  }

  function findOrdersByProductID(product_id){
    var commonOrderArr = new Array();
    var sortOrders = [];
    var needItems = []
    if(oldProductId == -1){
        var tempOrdersArr = new Array();
        for (let i = 0, j = 0; i < ordersArr.length; i++) {
          if (!scanedOrders.includes(ordersArr[i])) {
            tempOrdersArr[j] = ordersArr[i];
            j++;
          }
        }
        // console.log(tempOrdersArr);
        if(tempOrdersArr.length==0){
            return false;

        }
        
        for(var k in tempOrdersArr){
            // commonOrderArr[tempOrdersArr[k]] = needScanedOrdersSum[tempOrdersArr[k]];
            // console.log(tempOrdersArr[k])
            commonOrderArr[tempOrdersArr[k]] = setNeedScanedOrdersSum([tempOrdersArr[k]]);
        }
        // console.log(commonOrderArr);
        // return false;
        // oldProductId = product_id;
        setOldProductId(product_id);
        // createCanConfirmOrderHtml(commonOrderArr,1);
        compareOrderArr[oldProductId] = tempOrdersArr;
    }else{
        var count = 0;
        var tempSameOrder = [];
        
    }
 
    sortOrders = [];
    return true;

}

function arrayIntersection (a,b){
  var result = new Array();
  var loopArr = null;
  var compareArr = null;
  if(a.length<b.length){
      loopArr = a;
      compareArr = b;

  }else{
      loopArr = b;
      compareArr = a;
  }

  for(var i in loopArr){
      // if(jQuery.inArray(loopArr[i],compareArr)>=0){
      
      if (compareArr.includes(loopArr[i])) {
          result[i] = loopArr[i];
          result[i] = loopArr[i];
      }
  }
  return result;
}

function showSelectedOrder(code){
  if(typeof code == 'undefined'){
      // alertTip('Off-Shelf Order Abnormality.');
      NotificationManager.success('Off-Shelf Order Abnormality.');

      return;
  }
  // $('#orderSKUNum').html(needScanedOrdersSum[code].skuNum);
  // $('#orderItemNum').html(needScanedOrdersSum[code].itemNum);
  // $('#pp_name').html(needScanedOrdersSum[code].pp_name_en);
  // $('#shipMethod').html(needScanedOrdersSum[code].stCode);
  // $('#pickup_name').html(needScanedOrdersSum[code].pickup_name);
  // $("#tracking_number").val(needScanedOrdersSum[code].tracking_number);
  // $("#fpNum").html('#'+needScanedOrdersSum[code].sort_number);
  // $('#orderCode').html(code);
  setOrderCode(code);
  // shipMethod = needScanedOrdersSum[code].stCode;
  // packageNum = needScanedOrdersSum[code].packageNum;
}


  return (
    <div>
      
      {/* {showUpload ? ( */}
        <div className="content bdycontent">
          <SyncLoader
            css={override}
            size={15}
            margin={6}
            color={"#3E3E3E"}
            loading={loading}
          />

          <div className="container-fluid">
          <div className="card" style={{ padding: "1em" }}>
              <div className="row">
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <label>Off-Shelf Number</label>
                </div>
               
                <div className="col-sm-3 col-md-3 col-lg-3">
                  <input
                      type="text"
                      className="form-control"
                      onChange={handleSearchInvoice}
                      id="searchOrder"
                      autoComplete="off"
                      onKeyDown={(e) => {
                        if (e.key === "Enter")
                          scanOffshelf();
                        }}
                      // onKeyUp={scanOffshelf}
                      // onKeyDown={scanOffshelf}
                      ref={inputRef}
                      value={offshelfSearch}  />
                </div>
                <div className="col-sm-1 col-md-1 col-lg-1">
                  <button
                        style={{
                          background: "rgb(255, 160, 0)",
                          borderColor: "rgb(255, 160, 0)",
                          fontSize: "12px",
                          fontWeight: "300",
                        }}
                        className="btn btn-primary btn-fill"
                        onClick={() => scanOffshelf()}
                      >
                        Scan
                      </button>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <label>Product Barcode</label>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                  <input
                      type="text"
                      name="input_barcode"
                      className="form-control"
                      onChange={handleSearchBarcode}
                      id="product_sku1"
                      onKeyDown={(e) => {
                        if (e.key === "Enter")
                          confirmBarcode();
                        }}
                      // onKeyUp={() => confirmBarcode()}
                      // onKeyDown={() => confirmBarcode()}
                      autoComplete="off"
                      ref={inputRef2}
                      value={barcodeNumber} />
                </div>
                <div className="col-sm-1 col-md-1 col-lg-1">
                  <button
                        style={{
                          background: "rgb(255, 160, 0)",
                          borderColor: "rgb(255, 160, 0)",
                          fontSize: "12px",
                          fontWeight: "300",
                        }}
                        className="btn btn-primary btn-fill"
                        onClick={() => confirmBarcode()}
                      >
                        Confirm Barcode
                  </button>
                </div>
              </div>
            </div>
            
          </div>

          <div className="container-fluid">  
            <div className="card" style={{ padding: "1em" }}>
            <table width="100%" className="table table-hover" border="1">
              <tbody>
                <tr>
                  <td className="showtitle">Off-Shelf Number：</td>
                  <td align="center" width="250px"><span id="offshelfcode">{offshelfNumber}</span></td>
                  <td className="showtitle">Total Order(s)：</td>
                  <td className="showinfo"><span id="OrdersTotal">{orderCount}</span></td>
                  <td className="showtitle">Scanned Order(s)：</td>
                  <td className="showinfo"><span id="ScanOrdersTotal">{scanOrderCount}</span></td>
                </tr>
                <tr>
                  <td className="showtitle">Order Number：</td>
                  <td align="center" width="250px"><span id="orderCode">{orderCode}</span></td>
                  <td class="showtitle">Scanned Qty：</td>
                  <td class="showinfo"><span id="orderSKUNum">{scanOrderQty}</span></td>
                  {/* <td class="showtitle">Scanned SKU(s)：</td><td class="showinfo"><span id="scanSKUNum">0</span></td>	 */}
                </tr>
                
              </tbody>
            </table>
               
            <div id="pdfIframeWrap" style={{width:"10cm", height:"5cm", border:"2px solid #ccc", background:"#fff", margin:"5px 0", display: displayPdfDiv}}>
              <Iframe source={iframe} />
            </div>
            </div>
            
          </div>
          
        </div>
      {/*) : (    
        <h1>Hello</h1>
      )} */}
      
    </div>
  );
}
export default OrderList;
