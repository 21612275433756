import React, { Component } from "react";
import { Grid, Row, Col, Table, Button, Modal } from "react-bootstrap";
import axios from "axios";
import jwt_decode from "jwt-decode";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { NotificationManager } from "react-notifications";
import CustomButton from "../../components/CustomButton/CustomButton.jsx";
import Card from "../../components/Card/Card.jsx";
import SyncLoader from "react-spinners/SyncLoader";
import { Link } from "react-router-dom";
import _ from "lodash";
import isEmpty from "is-empty";
import ReactPaginate from "react-paginate";
import { getPriceFormatter } from "../../helpers/index.js";
import { Footer } from "react-bootstrap/lib/Modal.js";

const override = `
	position: fixed;
	display: block;
	top: 45%;
	left: 50%;
	transform: translateX(-50%);
	transform: translate(-50%, -50%);
	z-index: 9999;
`;

export default class AdminProduct extends Component {
  constructor( ) {
    super( );
    this.state = {
      loading: true,
      productsData: [],
      isChecked: [],  // Tracks the state of individual checkboxes
      isHeaderChecked: false  ,       
      currentPage: parseInt(localStorage.getItem("p.currentpagea")) || 1,
      showPerPage: 15,
      pageCount: 0,
      productTotalCount: 0,
      categoryList: [],
      supllierList: [],
      searchName: "",
      searchSku: "",
      query: {},
      modalShow: false,
      modalShow1: false,
      deleteProductId: "",
      deleteProductName: "",
      productPriceInfo: {},
      productSku: "00000000",
    };
  }

  componentDidMount() {
    // console.log(this.state.currentPage,parseInt(localStorage.getItem("p.currentpagea")) || 1);
    this.getProductsData(
      this.state.currentPage,
      this.state.showPerPage,
      this.state.query
    );
    console.log(this.state.query);
    this.setState({
      isChecked: Array(this.state.productsData.length).fill(false),
    });
    this.getCategoryList();
    this.getSupplierList();
  }
  getProductsData(page, perPage, searchParams) {
    console.log(searchParams);
    this.setState({ loading: true });
    axios
        .get(
            `/admin/getProductsAdmin?page=${page}&limit=${perPage}&query=${
                !_.isEmpty(searchParams) ? JSON.stringify(searchParams) : "{}"
            }`
        )
        .then((result) => {
            this.setState({
                productsData: result.data.items,
                productImageUrl: result.data.url,
                pageCount: Math.ceil(result.data.totalCount / this.state.showPerPage),
                currentPage: page,
                productTotalCount: result.data.totalCount,
                loading: false,
                query: searchParams,
            });
            localStorage.setItem("p.currentpagea", page);
            if (_.isEmpty(searchParams)) {
                document.getElementById("supplierList").selectedIndex = 0;
                document.getElementById("categoryList").selectedIndex = 0;
                this.setState({ searchName: "", searchSku: "" });
            }
        })
        .catch((err) => {
            // console.log(err)
            this.setState({ loading: false });
            NotificationManager.error(
                "Something went wrong while getting products data"
            );
        });
}
  handleHeaderCheckboxChange = () => {
    const newCheckedState = !this.state.isHeaderChecked;
    
    this.setState((prevState) => {
      // Only update isChecked if productsData has been loaded
      const newCheckedArray = prevState.productsData.length > 0
        ? Array(prevState.productsData.length).fill(newCheckedState)
        : [];
        
      return {
        isHeaderChecked: newCheckedState,
        isChecked: newCheckedArray,
      };
    });
  };
  

  handleCheckboxChange = (index) => {
    const newChecked = [...this.state.isChecked];
    newChecked[index] = !newChecked[index];
 
    this.setState({
      isChecked: newChecked,
      isHeaderChecked: newChecked.every(Boolean)  
    });
    const allChecked = newChecked.every((item) => item === true);

    this.setState({
      isChecked: newChecked,
      isHeaderChecked: allChecked, // Update header checkbox if all are selected
    });
  };

  handleDownloadSelected = () => {
    const { productsData, isChecked } = this.state;
    const selectedData = productsData.filter((_, index) => isChecked[index]);
  
    // Convert the selected data to CSV format
    const csvContent = selectedData.map((item) => {
      // Flattening the nested _id, supplier_id, and sid objects
      const id = item._id?.$oid || '';
      // const supplierId = item.supplier_id?.$oid || '';
      // const sid = item.sid?.$oid || '';
  
      // Flattening the stock object
      const stock = `CN Qty: ${item.stock?.cn_qty || 0}, BLK Qty: ${item.stock?.blk_qty || 0}, IN Qty: ${item.stock?.in_qty || 0}`;
  
      // Flattening and handling the imgUrl array
      const images = item.imgUrl?.map(img => img.src).join('; ') || '';
  
      // Handling the description by removing HTML tags and newlines
      const description = item.description 
        ? item.description.replace(/<[^>]*>?/gm, '').replace(/\n/g, ' ').replace(/"/g, '""') 
        : '';
  
      // Convert the item to a CSV row
      return [
        `"${id}"`,
        `"${item.name ? item.name.replace(/"/g, '""') : ''}"`,
        `"${description}"`,
        `"${images}"`,
        `"${item.price || ''}"`,
        `"${item.mrp || ''}"`,
        `"${item.sp || ''}"`,
        `"${item.cogs || ''}"`,
        `"${item.quantity || ''}"`,
        `"${item.weight || ''}"`,
        // `"${item.hsn || ''}"`,
        // `"${supplierId}"`,
        // `"${sid}"`,
        `"${stock}"`,
        // `"${item.gst || ''}"`
      ].join(",");
    }).join("\n");
  // ,"Supplier ID","SID","Stock","GST"
    // Add headers to the CSV content
    const headers = `"ID","Name","Description","Images","Price","MRP","SP","COGS","Quantity","Weight","HSN" \n`;
    const finalCsvContent = headers + csvContent;
  
    // Create a blob and a link to trigger the download
    const blob = new Blob([finalCsvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
  
    const a = document.createElement('a');
    a.href = url;
    a.download = 'selected_products.csv';
    a.click();
    URL.revokeObjectURL(url); // Clean up
  };
  
  getCategoryList() {
    this.setState({ loading: true });
    axios.get("/admin/categoryList").then((response) => {
      this.setState({ categoryList: response.data });
    });
  }

  getSupplierList() {
    this.setState({ loading: true });
    axios.get("/admin/supplierList").then((response) => {
      this.setState({ supllierList: response.data });
    });
    //console.log(supllierList)
  }
  downloadProduct() {
    let supplier = document.getElementById("supllierList").value;
    if (!isEmpty(supplier) && supplier != "Select Supplier") {
      let obj = {
        currentId: jwt_decode(localStorage.getItem("token")).id,
        supplierId: supplier,
        page: this.state.currentPage,
        limit: this.state.showPerPage,
      };
      this.setState({ loading: true });
      axios.post(`admin/downloadProductCsv/${supplier}`, obj).then((result) => {
        this.setState({ loading: false });
        if (result.data.error == 0) {
          window.open(result.data.response);
        } else if (result.data.error == 1) {
          alert(result.data.response);
        }
      });
    }
  }

  triggerHanleSearch = (e) => {
    e.persist();
    if (e.key === "Enter") {
      this.searchFunc();
    }
  };
triggerHanleSearch1 = (e) => {
    e.persist();
    if (e.key === "Enter") {
      this.searchFunc();
    }
  };
  searchBySku() {
    if (!_.isEmpty(this.state.searchSku)) {
      this.setState({ loading: true, searchName: "" });
      document.getElementById("supplierList").selectedIndex = 0;
      document.getElementById("categoryList").selectedIndex = 0;
      axios
        .get(`/admin/findBySku?sku=${this.state.searchSku}`)
        .then((result) => {
          if (result.data === 1) {
            NotificationManager.success("No Product Found on this SKU");
            this.setState({ loading: false });
          } else {
            let temp = [];
            temp.push(result.data.obj);
            this.setState({
              productsData: temp,
              pageCount: Math.ceil(temp.length / this.state.showPerPage),
              currentPage: 1,
              loading: false,
              productTotalCount: temp.length,
            });
            localStorage.setItem("p.currentpagea", 1);
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
          NotificationManager.error("Failed to get product data");
        });
    } else {
      this.searchFunc();
    }
  }
  callSearchFunc = (e) => {
    e.persist();
    const selectedSupplier = document.getElementById("supplierList").value;
    console.log("Selected Supplier ID:", selectedSupplier); // Debugging log
    if (selectedSupplier !== "Select Supplier") {
        this.setState({ searchSku: "" }, () => {
            this.searchFunc();
        });
    }
};
 
searchFunc = () => {
    let searchParams = {
        supplier_id: document.getElementById("supplierList").value,
        name: this.state.searchName,
    };
    console.log("Search Parameters:", searchParams); // Log the search parameters
    this.getProductsData(1, this.state.showPerPage, searchParams);
};


  handlePageClick = (e) => {
    this.getProductsData(
      e.selected + 1,
      this.state.showPerPage,
      this.state.query
    );
  };

  storeUniqueId = (item) => {
    localStorage.setItem("code", item);
    localStorage.setItem("from", "admin");
  };

  handleDeleteProduct = (item) => {
    this.setState({
      deleteProductId: item._id,
      modalShow: true,
      deleteProductName: item.name,
    });
  };

  showProductPrice = (item) => {
    axios.get(`/admin/getProductPrice?id=${item._id}`).then((result) => {
      this.setState({ modalShow1: true });
      this.setState({ productPriceInfo: result.data });
      this.setState({ productSku: result.data.sku });
    });
  };

  hardDeleteProduct() {
    this.setState({ loading: true, modalShow: false });
    axios
      .get(`/add/deleteProduct/${this.state.deleteProductId}`)
      .then((result) => {
        if (result.data === 1) {
          NotificationManager.success("Product deleted successfully");
          this.getProductsData(this.state.currentPage, this.state.showPerPage);
        } else NotificationManager.error("Failed to delete product, Try again");
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        NotificationManager.error("Failed to delete product, Try again");
      });
  }

  softDeleteProduct() {
    this.setState({ loading: true, modalShow: false });
    axios
      .get(`/add/softDeleteProduct/${this.state.deleteProductId}`)
      .then((result) => {
        if (result.data === 1) {
          NotificationManager.success("Product deleted successfully");
          this.getProductsData(this.state.currentPage, this.state.showPerPage);
        } else NotificationManager.error("Failed to delete product, Try again");
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        NotificationManager.error("Failed to delete product, Try again");
      });
  }
   
  handleOnUnSubcribe(data = "") {
    let checkedPids = [];

    if (data == "") {
      [...document.getElementsByClassName("select_products")].forEach((el) => {
        //
        if (el.checked) {
          checkedPids.push(el.value);
        }
        // el.checked = e.target.checked;
        //
      });
    } else {
      checkedPids = [data["id"]];
    }

    // console.log(checkedPids);

    // const obj = {
    //   arrOfIds: checkedPids,
    // };

    axios
      .post(`/admin/selectedProductsofapi`, {
        checkedPids,
      })
      .then((resData) => {
        if (resData.data.msg.success > 0) {
          NotificationManager.success("Product uploaded successfully");
        } else {
          NotificationManager.error("Something went wrong");
        }
      });
    // let mId = decode.id;
    // console.log("checkedPids", checkedPids);
    // console.log(this.state.productsData);
    //   let selectedData = [];
    //   for (let i = 0; i < checkedPids.length; i++) {
    //     for (let j = 0; j < this.state.productsData.length; j++) {
    //       if (checkedPids[i] === this.state.productsData[j]._id) {
    //         selectedData.push(this.state.productsData[j]);
    //       }
    //     }
    //   }

    //   // console.log(selectedData);

    //   let dropshipperObj = [];

    //   for (let i = 0; i < selectedData.length; i++) {
    //     console.log(selectedData[i].imgUrl.length);
    //     let imgObj = {};
    //     for (let j = 0; j < selectedData[i].imgUrl.length; j++) {
    //       imgObj.path = selectedData[i].imgUrl[j].src;
    //     }
    //     // console.log(selectedData[i]);
    //     // console.log("imgObj",imgObj);

    //     dropshipperObj.push({
    //       productID: selectedData[i]._id,
    //       is_display: "",
    //       shippingMethod: [],
    //       brand: "",
    //       product_visible: "",
    //       g_id: "",
    //       gst: "",
    //       product_sum: "",
    //       product_manage_status: "",
    //       productSKU: selectedData[i].skus[0],
    //       category_id: selectedData[i].cat_id,
    //       main_image: selectedData[i].imgUrl[0].src,
    //       productImages: imgObj,
    //       product_model: selectedData[i].pd_model,
    //       product_color: "",
    //       product_material: "",
    //       product_keywords1: "",
    //       product_keywords2: "",
    //       product_keywords3: "",
    //       product_keywords4: "",
    //       product_keywords5: "",
    //       product_short_descriptions: selectedData[i].name,
    //       product_pack_unit: "",
    //       product_length: selectedData[i].length,
    //       product_width: selectedData[i].width,
    //       product_height: selectedData[i].height,
    //       product_weight: selectedData[i].weight,
    //       pd_name: selectedData[i].name,
    //       pd_description: selectedData[i].description,
    //       product_status: selectedData[i].pd_status,
    //       product_location: "",
    //       stock: selectedData[i].stock,
    //       prices: selectedData[i].prices,
    //     });
    //   }
    //   console.log("dropshipperObj", dropshipperObj);

    //   const dropshipperObjApi = {
    //     HeaderRequest: {
    //       userid: "Leon",
    //       userkey: "VIGfMA0GCSqGSIb3DQEBAQUAA4",
    //       usertoken: "VIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBi",
    //       methodName: "ShopperrProduct",
    //     },
    //     addProductRequestInfo: dropshipperObj,
    //   };

    //   fetch("https://dropshipper.shopperr.in/webservice", {
    //     method: "POST",

    //     headers: {
    //       Accept: "application/json, text/plain, */*",

    //       "Content-Type": "application/json",
    //     },

    //     body: dropshipperObjApi,
    //   })
    //     .then((res) => res.json())

    //     .then((res) => console.log(res));
    // }
  }
  render() {
    return (
      <div>
        <SyncLoader
          css={override}
          size={15}
          margin={6}
          color={"#3E3E3E"}
          loading={this.state.loading}
        />
        <br></br>
        <div
          className={this.state.loading ? "parentDisable" : ""}
          id="priceModalpopup"
        >
          <Modal
            show={this.state.modalShow}
            onHide={() => this.setState({ modalShow: false })}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>What kind of delete you want to make on this?</h5>
              <br></br>
              <p>{this.state.deleteProductName}</p>
              <br></br>
            </Modal.Body>
            <Modal.Footer>
              <button
                style={{ color: "white", background: "green" }}
                onClick={() => this.setState({ modalShow: false })}
              >
                Cancel
              </button>
              <button
                style={{
                  color: "white",
                  background: "orange",
                  marginTop: "8px",
                }}
                onClick={() => this.softDeleteProduct()}
              >
                Soft Delete
              </button>
              <button
                style={{ color: "white", background: "red", marginTop: "8px" }}
                onClick={() => this.hardDeleteProduct()}
              >
                Hard Delete
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            size="lg"
            className="planModal"
            show={this.state.modalShow1}
            onHide={() => this.setState({ modalShow1: false })}
            aria-labelledby="example-modal-sizes-title-lg"
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Price Detail #{this.state.productSku}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th className="text-center">Sea Price</th>
                    <th className="text-center">Air Price</th>
                    <th className="text-center">DropShipper</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Ex Factory Price</td>
                    <td className="text-right">
                      {this.state.productPriceInfo.seaPrice
                        ? this.state.productPriceInfo.seaPrice.factoryPrice
                        : "-"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.finalAirPrice
                        ? this.state.productPriceInfo.finalAirPrice.factoryPrice
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.dropshipperPrice
                        ? this.state.productPriceInfo.dropshipperPrice
                            .factoryPrice
                        : "Na"}
                    </td>
                  </tr>
                  <tr>
                    <td>SWC</td>
                    <td className="text-right">
                      {this.state.productPriceInfo.seaPrice
                        ? this.state.productPriceInfo.seaPrice.SWC
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.finalAirPrice
                        ? this.state.productPriceInfo.finalAirPrice.SWC
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.dropshipperPrice
                        ? this.state.productPriceInfo.dropshipperPrice.SWC
                        : "Na"}
                    </td>
                  </tr>
                  <tr>
                    <td>CNF Price</td>
                    <td className="text-right">
                      {this.state.productPriceInfo.seaPrice
                        ? this.state.productPriceInfo.seaPrice.cnfPrice
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.finalAirPrice
                        ? this.state.productPriceInfo.finalAirPrice.cnfPrice
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.dropshipperPrice
                        ? this.state.productPriceInfo.dropshipperPrice.cnfPrice
                        : "Na"}
                    </td>
                  </tr>
                  <tr>
                    <td>Lastmile</td>
                    <td className="text-right">
                      {this.state.productPriceInfo.seaPrice
                        ? this.state.productPriceInfo.seaPrice.lastMile
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.finalAirPrice
                        ? this.state.productPriceInfo.finalAirPrice.lastMile
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.dropshipperPrice
                        ? this.state.productPriceInfo.dropshipperPrice.lastMile
                        : "Na"}
                    </td>
                  </tr>
                  <tr>
                    <td>Duty</td>
                    <td className="text-right">
                      {this.state.productPriceInfo.seaPrice
                        ? this.state.productPriceInfo.seaPrice.duty
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.finalAirPrice
                        ? this.state.productPriceInfo.finalAirPrice.duty
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.dropshipperPrice
                        ? this.state.productPriceInfo.dropshipperPrice.duty
                        : "Na"}
                    </td>
                  </tr>
                  <tr>
                    <td>COGS</td>
                    <td className="text-right">
                      {this.state.productPriceInfo.seaPrice
                        ? this.state.productPriceInfo.seaPrice.cogs
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.finalAirPrice
                        ? this.state.productPriceInfo.finalAirPrice.cogs
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.dropshipperPrice
                        ? this.state.productPriceInfo.dropshipperPrice.cogs
                        : "Na"}
                    </td>
                  </tr>
                  <tr>
                    <td>GST</td>
                    <td className="text-right">
                      {this.state.productPriceInfo.seaPrice
                        ? this.state.productPriceInfo.seaPrice.gstImpact
                        : "Na"}{" "}
                      (
                      {this.state.productPriceInfo.seaPrice
                        ? this.state.productPriceInfo.seaPrice.gstImpactPer
                        : "0"}
                      %)
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.finalAirPrice
                        ? this.state.productPriceInfo.finalAirPrice.gstImpact
                        : "Na"}{" "}
                      (
                      {this.state.productPriceInfo.finalAirPrice
                        ? this.state.productPriceInfo.finalAirPrice.gstImpactPer
                        : "0"}
                      %)
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.dropshipperPrice
                        ? this.state.productPriceInfo.dropshipperPrice.gstImpact
                        : "Na"}{" "}
                      (
                      {this.state.productPriceInfo.dropshipperPrice
                        ? this.state.productPriceInfo.dropshipperPrice
                            .gstImpactPer
                        : "0"}
                      %)
                    </td>
                  </tr>
                  <tr>
                    <td>Weight</td>
                    <td className="text-right">
                      {this.state.productPriceInfo.seaPrice
                        ? this.state.productPriceInfo.seaPrice.weight
                        : "Na"}{" "}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.finalAirPrice
                        ? this.state.productPriceInfo.finalAirPrice.weight
                        : "Na"}{" "}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.dropshipperPrice
                        ? this.state.productPriceInfo.dropshipperPrice.weight
                        : "Na"}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>Product Margin</td>
                    <td className="text-right">
                      {this.state.productPriceInfo.seaPrice
                        ? this.state.productPriceInfo.seaPrice.markup
                        : "Na"}{" "}
                      (
                      {this.state.productPriceInfo.seaPrice
                        ? this.state.productPriceInfo.seaPrice.markupPer
                        : "0"}
                      %)
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.finalAirPrice
                        ? this.state.productPriceInfo.finalAirPrice.markup
                        : "Na"}{" "}
                      (
                      {this.state.productPriceInfo.finalAirPrice
                        ? this.state.productPriceInfo.finalAirPrice.markupPer
                        : "0"}
                      %)
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.dropshipperPrice
                        ? this.state.productPriceInfo.dropshipperPrice.markup
                        : "Na"}{" "}
                      (
                      {this.state.productPriceInfo.dropshipperPrice
                        ? this.state.productPriceInfo.dropshipperPrice.markupPer
                        : "0"}
                      %)
                    </td>
                  </tr>
                  <tr>
                    <td>Retailer Price (WO GST)</td>
                    <td className="text-right">
                      {this.state.productPriceInfo.seaPrice
                        ? this.state.productPriceInfo.seaPrice.priceWithoutGst
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.finalAirPrice
                        ? this.state.productPriceInfo.finalAirPrice
                            .priceWithoutGst
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.dropshipperPrice
                        ? this.state.productPriceInfo.dropshipperPrice
                            .priceWithoutGst
                        : "Na"}
                    </td>
                  </tr>
                  <tr>
                    <td>Price Including GST</td>
                    <td className="text-right">
                      {this.state.productPriceInfo.seaPrice
                        ? this.state.productPriceInfo.seaPrice.price
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.finalAirPrice
                        ? this.state.productPriceInfo.finalAirPrice.price
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.dropshipperPrice
                        ? this.state.productPriceInfo.dropshipperPrice.price
                        : "Na"}
                    </td>
                  </tr>
                  <tr>
                    <td>Consumer Price</td>
                    <td className="text-right">
                      {this.state.productPriceInfo.seaPrice
                        ? this.state.productPriceInfo.seaPrice.cunsumerPrice
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.finalAirPrice
                        ? this.state.productPriceInfo.finalAirPrice
                            .cunsumerPrice
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.dropshipperPrice
                        ? this.state.productPriceInfo.dropshipperPrice
                            .cunsumerPrice
                        : "Na"}
                    </td>
                  </tr>
                  <tr>
                    <td>MSRP</td>
                    <td className="text-right">
                      {this.state.productPriceInfo.seaPrice
                        ? this.state.productPriceInfo.seaPrice.mrp
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.finalAirPrice
                        ? this.state.productPriceInfo.finalAirPrice.mrp
                        : "Na"}
                    </td>
                    <td className="text-right">
                      {this.state.productPriceInfo.dropshipperPrice
                        ? this.state.productPriceInfo.dropshipperPrice.mrp
                        : "Na"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Modal.Body>
          </Modal>
          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <div className="row">
                <div className="col-sm-3 col-md-3 col-lg-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by SKU"
                    value={this.state.searchSku}
                    autoComplete="off"
                    title="Hit Enter for the result"
                    onKeyPress={this.triggerHanleSearch}
                    onChange={(e) => {
                      this.setState({
                        searchSku: e.target.value,
                        searchName: "",
                        errors: {},
                      });
                      const supplierDropdown = document.getElementById("supplierList");
                      const categoryDropdown = document.getElementById("categoryList");
                    
                      if (supplierDropdown) supplierDropdown.selectedIndex = 0;
                      if (categoryDropdown) categoryDropdown.selectedIndex = 0;
                    }}
                  />
                </div>
                <div className="col-sm-3 col-md-3 col-lg-3">
    <input 
        type="text"
        className="form-control"
        placeholder="Search by Product Name"
        id="searchByName"
        title="Hit Enter for the result"
        value={this.state.searchName}
        autoComplete="off"
        onKeyPress={this.triggerHandleSearch1}
        onChange={(e) => this.setState({searchName: e.target.value, searchSku: "", errors: {}})}
    />
</div>
                <div className="col-sm-3 col-md-3 col-lg-3">
                  <select
                    className="form-control"
                    id="supplierList"
                    onChange={this.callSearchFunc}
                  >
                    <option value="Select Supplier">Select Supplier</option>
                    {this.state.supllierList.map((data) => {
                      const { _id, supplier_id } = data;
                      return (
                        <option key={_id} value={_id.toString()}>
                          {supplier_id}
                        </option>
                      );
                    })}
                  </select>   
                </div>
                {/* <div className="col-sm-3 col-md-3 col-lg-3"> */}
                  {/* <select
                    className="form-control"
                    id="categoryList"
                    onChange={this.callSearchFunc}
                  >
                    <option value="Select Category">Select Category</option>
                    {this.state.categoryList.map((data) => {
                      const { _id, category } = data;
                      return (
                        <option key={_id} value={_id}>
                          {category}
                        </option>
                      );
                    })}
                  </select> */}
                {/* </div> */}
                {/* <div className="col-sm-1 col-md-1 col-lg-1">
                  <button
                    className="btn-fill btn btn_new btn-default"
                    style={{ margin: "0px", width: "100%" }}
                    onClick={() => this.downloadProduct()}
                  >
                  <i className="fa fa-download" aria-hidden="true"></i>
                  </button>
                </div>{" "} {" "} */}
                <div className="col-sm-3 col-md-3 col-lg-3">
                  <button
                    className="btn-fill btn btn_new btn-default"
                    onClick={this.handleDownloadSelected}
                  >
                    Export Selected
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="card" style={{ padding: "1em" }}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.getProductsData(1, this.state.showPerPage, {})
                }
              >
                <div className="form-group">
                  <h5 className="text-bold">
                    Total Products:{" "}
                    <span className="text-muted" style={{color:"black"}}>
                      {this.state.productTotalCount}
                    </span>
                    &nbsp;<i className="fa fa-refresh" aria-hidden="true"></i>
                  </h5>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table striped bordered hover">
                  <thead>
                    <tr className="d-flex">
                      <th scope="col" style={{color:"black",fontWeight:"600px"}}>   <input
                  type="checkbox"
                  checked={this.state.isHeaderChecked}
                  onChange={this.handleHeaderCheckboxChange}
                />
            </th>
                      <th scope="col" style={{color:"black",fontWeight:"600px"}}>Image</th>
                      <th scope="col" style={{color:"black",fontWeight:"600px"}}>Supplier Name</th>
                      <th scope="col" style={{color:"black",fontWeight:"600px"}}>Product Name</th>
                      <th scope="col" style={{color:"black",fontWeight:"600px"}}>SKU</th>
                      <th scope="col" style={{color:"black",fontWeight:"600px"}}>Category</th>
                      <th scope="col" style={{color:"black",fontWeight:"600px"}}>Ex Factory Price</th>
                      <th scope="col" style={{color:"black",fontWeight:"600px"}}>Selling Price</th>
                      <th scope="col" style={{color:"black",fontWeight:"600px"}}>Operations</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!_.isEmpty(this.state.productsData) ? (
                      this.state.productsData.map((item, index) => {
                        const {
                          _id,
                          image,
                          supplierName,
                          name,
                          sellingPrice,
                          code,
                          category,
                          price,
                          orders,
                          revenue,
                          productUrl,
                          globalProductUrl,
                        } = item;
                        return (
                          <tr key={index} className="d-flex text-center">
                            <td>
                            <input
                        type="checkbox"
                        checked={this.state.isChecked[index]}  
                        onChange={() => this.handleCheckboxChange(index)}
                    />
                            </td>
                            <td style={{ width: "12%" }}>
                              {!_.isEmpty(image) ? (
                                <img
                                  style={{ height: "90px", width: "100px" }}
                                  src={image}
                                />
                              ) : (
                                "Image not Available"
                              )}
                            </td>

                            <td style={{color:"#FFC500"}}>{supplierName}</td>
                            <td>{name}</td>
                            <td style={{color:"#8383EC"}}>{code}</td>
                            <td style={{color:"#FFC500"}}>{category}</td>
                            <td style={{color:"#8383EC"}}>{getPriceFormatter(price)}</td>
                            <td>
                              <span className="text-primary">
                                <i
                                  className="fa fa-bar-chart cursor-poiner"
                                  title="View Product Price"
                                  style={{ fontSize: "16px", color: "#42d0ed" }}
                                  aria-hidden="true"
                                  onClick={() => this.showProductPrice(item)}
                                ></i>
                              </span>
                            </td>
                            <td style={{ width: "140px" }}>
                              {/* <span style={{ paddingRight: "6px" }}>
                                <a href={globalProductUrl} target="_blank">
                                  <i
                                    className="fa fa-globe"
                                    title="View Product On Global"
                                    style={{ cursor: "poiner" }}
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                              <span style={{ paddingRight: "6px" }}>
                                <a href={productUrl} target="_blank">
                                  <i
                                    className="fa fa-eye"
                                    title="View Product On Ds"
                                    style={{ cursor: "poiner" }}
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span> */}
                              <span style={{ paddingRight: "6px" }}>
                                <Link
                                  to="/admin/editProduct"
                                  onContextMenu={(e) => e.preventDefault()}
                                >
                                  <i
                                    className="fa fa-pencil"
                                    style={{
                                      cursor: "poiner",
                                      fontSize: "22px",
                                    }}
                                    aria-hidden="true"
                                    onClick={() => this.storeUniqueId(code)}
                                  ></i>
                                </Link>
                              </span>
                              <span style={{ paddingRight: "6px" }}>
                                <Link
                                  to="/admin/viewProduct"
                                  onContextMenu={(e) => e.preventDefault()}
                                >
                                  <i
                                    className="fa fa-eye"
                                    style={{
                                      cursor: "poiner",
                                      fontSize: "22px",
                                    }}
                                    aria-hidden="true"
                                    onClick={() => this.storeUniqueId(code)}
                                  ></i>
                                </Link>
                              </span>
                              <span style={{ paddingRight: "6px" }}>
                                <Link
                                  // to="/admin/editProduct"
                                  onContextMenu={(e) => e.preventDefault()}
                                >
                                  <i
                                    className="fa fa-info-circle"
                                    style={{
                                      cursor: "poiner",
                                      fontSize: "22px",
                                    }}
                                    // aria-hidden="true"
                                    // onClick={() => this.storeUniqueId(code)}
                                  ></i>
                                </Link>
                              </span>
                               <span style={{ paddingRight: "6px" }}>
                                <Link
                                  to="/admin/DisabledProduct"
                                  onContextMenu={(e) => e.preventDefault()}
                                >
                                  <i
                                    className="fa fa-ban"
                                    style={{
                                      cursor: "poiner",
                                      fontSize: "22px",
                                    }}
                                    aria-hidden="true"
                                    onClick={() => this.storeUniqueId(code)}
                                  ></i>
                                </Link>
                              </span>
                              <span className="text-danger">
                                <i
                                  className="fa fa-trash cursor-poiner"
                                  style={{ fontSize: "22px", cursor: "poiner" }}
                                  aria-hidden="true"
                                  onClick={() => this.handleDeleteProduct(item)}
                                ></i>
                              </span>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan="11"
                          className="text-center"
                          style={{ fontSize: "20px" }}
                        >
                          No Product Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {!_.isEmpty(this.state.productsData) && (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  forcePage={this.state.currentPage - 1}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={10}
                  onPageChange={this.handlePageClick.bind(this)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>
          </div>
          <br></br>
        </div>
      </div>
    );
  }
}
